import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { AiFillHome } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import {FaBluetooth} from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom";

import "../css/BottomNav.css";
import "../css/global.css";

const Navbar = ({ value }) => {
    return (
        <div>
            <BottomNavigation
                sx={{ width: "100%", position: "fixed", bottom: 0,height:"10vh" }}
                value={value}
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<AiFillHome style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/home"
                />
                <BottomNavigationAction
                    label="Customers"
                    icon={<BiUserCircle style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/customer"
                />
                <BottomNavigationAction
                    label="More"
                    icon={<FiMoreHorizontal style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/more"
                />

                {/* <BottomNavigationAction
                    label="Bluetooth"
                    icon={<FaBluetooth style={{height:"35%",width:"35%"}}/>}
                    showLabel={true}
                    LinkComponent={Link}
                    to="/print"
                /> */}
            </BottomNavigation>
        </div>
    );
};

export default Navbar;
