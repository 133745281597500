import React from "react";
import Header from "../components/header";
import API from "../services/API";
import API2 from "../services/API";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import "../css/Complaints.css";
import "../css/global.css";
import LoadingBar from "react-top-loading-bar";
import { useTranslation } from "react-i18next";

export default function Complaints() {
  const [Complaints, setComplaints] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedComplaint, setSelectedComplaint] = useState([]);
  const [compStatus, setCompStatus] = useState('');
  const [compRemark, setCompRemark] = useState('');
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const headerprops = {
    text: "View Complaints",
    height: "10vh",
  };
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );

  const fetchComplaints = () => {
    API.viewCompalintAPI({ agent_id: user.agentId })
      .then((response) => {
        setProgress(60)
        setComplaints(response.data.complaints);
        setProgress(100)
        setLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setProgress(100)
        setLoading(false);
      });
  };

  const handleCompStatus = (e) => {
    setCompStatus(e.target.value);
  }

  const handleCompRemark = (e) => {
    setCompRemark(e.target.value);
  }

  const handleComplaint = () => {
    try{
      setAlert(false);
      setLoading(true);
      let flag = '';
      compStatus === 'CLOSED'? flag = 'B': flag = 'A';
      let data = {
        flag: flag,
        mcomp_id: selectedComplaint.COMP_ID,
        mcmp_Type: compStatus,
        mcmp_detail: compRemark
      }

      API2.updateComplaint(data)
      .then((response) => {
        setLoading(false);
        setCompRemark('');
        console.log("Response :",response);
        if(response.data.p_out_mssg_flg === 'S'){
          fetchComplaints();
        }
      })

      .catch((error) => {
        setLoading(false);
        setCompRemark('');
        console.log(error);
      });
    }catch(e){
      console.error("Encountered an error :",e);
    }
  };

  useEffect(() => {
    setLoading(true);
    setProgress(30)
    fetchComplaints();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const CompViewList = ({ complaints }) => {
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    };

    const formatTime = (dateString) => {
      const date = new Date(dateString);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes}${amPm}`;
    };

    const handleComplaintClick = (complaint) => {
      setSelectedComplaint(complaint);
      console.log(complaint);
      setAlert(true);
    };

    const CompViewDataList = complaints.map((data) => (
      <div
        className="complaints-data-div"
        onClick={() => handleComplaintClick(data)}
      >
        <tr>
          <td className="complaints-name">{data.NAME}</td>
        </tr>
        <tr className="comp-line2">
          <td className="complaints-id">{data.CUSTOMER_ID}</td>
          <td></td>
          <td>{formatTime(data.COMP_DATE)}</td>
          <td className="complaint-date">{formatDate(data.COMP_DATE)}</td>
        </tr>
        <tr>
          <td className="Place-complaint">{data.AREA}</td>
          <td></td>
          <td></td>
          <td
            className="complaint-status"
            style={
              data.COMP_STATUS === "REGISTERED"
                ? { color: "#DC1515" }
                : { color: "#0081B3" }
            }
          >
            {data.COMP_STATUS}
          </td>
        </tr>
      </div>
    ));
    return <>{CompViewDataList}</>;
  };

  return (
    <>
      <Header name={t("Complaints_lbl_Header")} />
      <LoadingBar
        color="#f11946"
        height={4}
        shadow={true}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container-complaints">
          {/* {isLoading && (
            <Spinner
              animation="border"
              variant="info"
              style={{ marginTop: "100px" }}
            />
          )} */}
        {
          isLoading ?
          <div style={{ display: "flex", justifyContent: "center", height: "90vh"}}>
            <Spinner
              animation="border"
              variant="info"
              style={{ marginTop: "100px" }}
            />
          </div>
          :
          <table className="complaints-table">
            <CompViewList complaints={Complaints} />
          </table>
        }
        <Dialog
          open={alert}
          onClose={() => setAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ borderRadius: "25px" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{
              fontFamily: "Noto Sans",
              marginLeft: "5%",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              color: "#333333",
            }}
          >
            {t("Complaints_dialog_heading")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginLeft: "5%" }}>
              {/* One Horizontal Line */}
              <div className="alert-line"></div>
              <div>
                <p>{t("Complaints_dialog_complaint_id")} : {selectedComplaint.COMP_ID} </p>
                <p>
                  {t("Complaints_dialog_complaint_date")} : {formatDate(selectedComplaint.COMP_DATE)}
                </p>
                <p>{t("Complaints_dialog_complaint_type")} : {selectedComplaint.CMP_TYPE}</p>
                <p>{t("Complaints_dialog_complaint_desc")} : {selectedComplaint.CMP_DETAIL}</p>
              </div>
            </DialogContentText>
            <DialogTitle
              id="alert-dialog-title"
             
              style={{
              fontFamily: "Noto Sans",
              marginLeft: "5%",
              paddingLeft: "0%",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              color: "#333333",
            }}
            >
              {t("Complaints_dialog_complaint_status")}
            </DialogTitle>
            {/* <DialogContentText style={{ marginLeft: "5%" }}> */}
            <DialogContentText>
              {/* One Horizontal Line */}
              <div className="alert-line"></div>
              <div>
                <select
                  name="status"
                  id="status"
                  style={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    border: "none",
                    fontFamily: "Noto Sans",
                  }}
                  onChange={(e) => handleCompStatus(e)}
                  value={compStatus}
                >
                  <option value="REGISTERED">REGISTERED</option>
                  <option value="ON PROCESS">ON PROCESS</option>
                  <option value="CLOSED">CLOSED</option>
                </select>
                <input
                  type="text"
                  placeholder={t("Complaints_dialog_complaint_comment_placeholder")}
                  style={{
                    width: "100%",
                    height: "40px",
                    marginLeft: "5%",
                    borderRadius: "10px",
                    border: "none",
                    fontFamily: "Noto Sans",
                  }}
                  onChange={(e) => handleCompRemark(e)}
                />
              </div>
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                background: "var(--primay-app-color)",
                color: "white",
                border: "none",
                borderRadius: "20px",
                margin: "15px",
                padding: "10px",
              }}
              onClick={ handleComplaint }
            >
              {t("Complaints_dialog_button")}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
