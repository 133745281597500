  
const OwnerData = ({ ownerid, owneramt, ownername, paymentMode }) => {
    return (
        <div className="report-data-div">
            <div className="report-data-owner">
                <p className="report-data-owner-name">{ownername}</p>
                <p className="report-data-owner-amt">₹{owneramt}</p>
            </div>
            <div className="report-data-owner">
                <p className="report-data-owner-id">{ownerid}</p>
                <p className="report-data-payment-mode">            
                    {/* {(() => {
                    switch({paymentMode}) {
                        case 1:
                            return 'Cash';
                        case 2:
                            return 'Cheque';
                        case 3:
                            return 'Online Payment';
                        default:
                            return 'Other';
                    }
                    })()} */}
                    {
                        (paymentMode==1)? 'Cash':
                        (paymentMode==2)? 'Cheque':
                        (paymentMode==3)? 'Online':
                        (paymentMode==8)? 'Net Banking':'Other'
                    }
                </p>
            </div>
        </div>
    );
};
export default OwnerData;
