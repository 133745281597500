import axios from "axios";

const API = axios.create({
  baseURL: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});
const API1 = axios.create({
  baseURL: "https://h45om730i6.execute-api.ap-south-1.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});
const API2 = axios.create({
  baseURL: "https://gxk7f7615b.execute-api.us-west-2.amazonaws.com/production/",
  headers: {
    "Content-Type": "application/json",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loginAPI(value) {
    return API.post("cableguy2-mobile-user-login-new", value);
  },

  collectbill(value) {
    return API.post("cableguy-appupsyncmodified", value);
  },

  COLLECT_SMS1(value) {
    return API1.post("crmsmscollectbillandrenew", value);
  },
  agentSummaryAPI(value) {
    return API.post("getagentsummary", value);
  },
  cashfreepaymentlink(value) {
    return API.post("onclickgeneratecashfreelink", value);
  },
  monthlyReportAPI(value) {
    return API.post("mobilecollectionreport", value);
  },

  dailyReportAPI(value) {
    return API.post("mobilecollectionreport", value);
  },
  OwnerMonthlyReportAPI(value) {
    return API.post("filtermobilecollectionreport", value);
  },

  agentAreaSummaryAPI(value) {
    return API.post("mobile-agentareasummary", value);
  },

  stbListingAPI(value) {
    return API.post("cableguy2-get-stb-details-test", value);
  },

  subscriptionExpiryReportAPI(value) {
    return API.post("cableguy2-mobile-expiry-report", value);
  },

  subscriptionExpiryReportCountAPI(value) {
    return API.post("cableguy2-mobile-cust-report-based-on-stb", value);
  },
  getcustomerbillhistory(value) {
    return API.post("getcustomerbillhistory", value);
  },
  dropdownAgentDataAPI(value) {
    return API.post("cableguy2-get-area-and-agent-list", value);
  },

  viewCompalintAPI(value) {
    return API.post("getmobileassignedcomplaints", value);
  },

  customerSummary(value) {
    return API.post("mobile-agent-op-summary", value);
  },

  allCustomerData(value) {
    return API.post("down-sync-offline", value);
  },

  recordVisit(value) {
    return API.post("cableguy2-barcode", value);
  },
  lastPaymentHistory(value) {
    return API.post("getmobilelastbill", value);
  },
  STBHistory(value) {
    return API.post("get-customer-stb-details", value);
  },
  getCustomerInfo(value) {
    return API.post("cableguy2-mobile-data-fetch-test", value);
  },
  editCustomerInfo(value) {
    return API.post("mobile-edit-customer-info", value);
  },
  updateCustomerlocation(value) {
    return API.post("update_customer_longlat", value);
  },
  editSTBInfo(value) {
    return API.post("mobileeditstbinfo_new", value);
  },
  addComplaint(value) {
    return API.post("managecomplaints", value);
  },
  activePacks(value) {
    return API.post("cableguy2-get-active-packs-test", value);
  },
  allPacks(value) {
    return API.post("cableguy-mobile-all-packs", value);
  },
  ADDallPacks(value) {
    return API.post("mobile_add_channel_all_pack", value);
  },
  modifyPacks(value) {
    return API.post("cableguy2-mobile-modify-packs-test", value);
  },
  addChanel(value) {
    return API.post("mobile-add-channel", value);
  },
  updateComplaint(value) {
    return API2.post("updatecomplaintstatus", value);
  },
  fetchPackages(value) {
    return API.post("cableguy-mobile-all-packs", value);
  },
  fetchArea(value) {
    return API.post("cableguy2-get-area-and-agent-list", value);
  },
  createCustomer(value) {
    return API.post("managecustomer-prcnew",value);
  }
};
