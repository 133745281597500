import React from "react";
import API from "../services/API";
// import { useEffect } from "react";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import EditSTB from "../assets/edit_stb.png";
import { FaAngleLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";
// import LoadingOverlay from "react-loading-overlay";
import "../css/STBListing.css";
import "../css/global.css";
import "../css/header.css";

export default function ADDSTBListing() {
  const navigate = useNavigate();
  const location = useLocation();
  const [customer, setcustomer] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [Customer, setCustomer] = useState(
    JSON.parse(localStorage.getItem("customerDetails"))
  );
  const [stb, setStb] = useState(localStorage.getItem("stb"));
  const [vc, setVc] = useState(localStorage.getItem("vc"));
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!customer) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    console.log(customer);
    const request = {
      customer_num: customer.customerId,
    };

    API.stbListingAPI(request)
      .then((response) => {
        console.log(response.data.stbList);
        setData(response.data.stbList);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <LoadingOverlay active={isLoading} spinner text="Please Wait...">
        <div className="STBs-Listing-header">
          <table className="STBs-Listing-Header-Table">
            <tr className="STBs-Listing-Table-tr">
              <td className="STBs-Listing-Col-1">Name</td>
              <td className="STBs-Listing-Col-2"> : </td>
              <td className="STBs-Listing-Col-3">{customer.customerName}</td>
            </tr>
            <tr>
              <td className="STBs-Listing-Col-1">Customer ID</td>
              <td className="STBs-Listing-Col-2"> : </td>
              <td className="STBs-Listing-Col-3">
                {customer.managedCustomerId}
              </td>
            </tr>
          </table>
        </div>
        <div className="STBs-MainContainer">
          {data.map((item) => (
            <div
              className="STBs-CardsContainer"
              onClick={() =>
                navigate("/customer/collectPayment/Mutiple_AddChannel", {
                  state: item,
                })
              }
            >
              <div className="STBs-Cards">
                <div className="STBs-CardImg">
                  <img className="STBs-ImgIcon" alt="stb" src={EditSTB} />
                </div>
                <div className="STBs-CardInfo">
                  <table className="STBs-Listing-Header-Table2">
                    <tr className="STBs-Listing-Table-tr2">
                      <td className="STBs-Listing-Col-1-2">Expiry Date</td>
                      <td className="STBs-Listing-Col-2-2"> : </td>
                      <td
                        className="STBs-Listing-Col-3-2"
                        style={{ color: "red", fontWeight: "600" }}
                      >
                        {item.PRE_END_DATE}
                      </td>
                    </tr>
                    <tr className="STBs-Listing-Table-tr2">
                      <td className="STBs-Listing-Col-1-2">Pack Price</td>
                      <td className="STBs-Listing-Col-2-2"> : </td>
                      <td className="STBs-Listing-Col-3-2">
                        ₹{item.monthly + item.tax}
                      </td>
                    </tr>
                    <tr className="STBs-Listing-Table-tr2">
                      <td className="STBs-Listing-Col-1-2">VC No</td>
                      <td className="STBs-Listing-Col-2-2"> : </td>
                      <td className="STBs-Listing-Col-3-2">{item.VC_NUMBER}</td>
                    </tr>
                    <tr className="STBs-Listing-Table-tr2">
                      <td className="STBs-Listing-Col-1-2">STB No</td>
                      <td className="STBs-Listing-Col-2-2"> : </td>
                      <td className="STBs-Listing-Col-3-2">
                        {item.STB_NUMBER}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </LoadingOverlay>
    </>
  );
}
