import React from "react";
import "../css/EditCustomer.css";
import Header from "../components/header";

import { useLocation, useNavigate } from "react-router-dom";
import API from "../services/API";
import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";

import MyDialog_prop from "../components/MyDialog_prop";
export default function EditCustomer() {
  const header = {
    text: "Edit Customer",
    height: "10vh",
  };
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  const [dropDownAreaData, setDropDownAreaData] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();
  const [DialogContentTitle, setDialogContentTitle] = useState("");
  const [DialogSubTitle, setDialogSubTitle] = useState("");
  const [customer, setCustomer] = useState(location.state);
  const [customerName, setCustomerName] = useState(customer.customerName);
  const [customerPhone, setCustomerPhone] = useState(customer.phone);
  const [customerAddress, setCustomerAddress] = useState(customer.address);
  const [customerAreaId, setCustomerAreaId] = useState(customer.AREA_ID);
  const [customerAreaName, setCustomerAreaName] = useState("");
  const [apiResponseTitle, setAPIResponseTitle] = useState("");
  const [apiResponseContent, setAPIResponseContent] = useState("");
  const [alert, setalert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isPhoneReadOnly, setPhoneReadOnly] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    if (user.techFlag === "N") {
      setPhoneReadOnly(false);
    } else {
      setPhoneReadOnly(true);
    }
  }, []);
  useEffect(() => {
    API.dropdownAgentDataAPI({ operatorId: user.operatorId })
      .then((response) => {
        setDropDownAreaData(response.data.all_areas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const DropDownArea = () => {
    const DropDownAreaData = dropDownAreaData.map((data) => {
      return <option value={data.id}>{data.area_name}</option>;
    });
    return DropDownAreaData;
  };

  const AreaNameForID = (id) => {
    const area = dropDownAreaData.filter((data) => {
      return data.id === parseInt(id);
    });
    console.log(area);
    // setCustomerAreaName(area[0].area_name);
    setCustomerAreaName(area[0].area_name);
  };
  const dismiss_dialog = () => {
    setalert(false);
    /*toLink: "/customer/collectPayment/addChannel",
          state: customerDetail*/
    navigate("/customer/collectPayment/", {
      state: location.state,
    });
  };
  const handleAPI = () => {
    if (
      customerName === "" ||
      customerPhone === "" ||
      customerAddress === "" ||
      customerAreaId === ""
    ) {
      setAPIResponseTitle("Error");
      setAPIResponseContent("Please fill all the fields");
      setalert(true);
      return;
    }
    setIsLoading(true);
    AreaNameForID(customerAreaId);
    API.editCustomerInfo({
      customer_id: customer.customerId,
      agent_id: user.agentId,
      operator_id: user.operatorId,
      customer_name: customerName,
      customer_phone: customerPhone,
      customer_address: customerAddress,
      Area: customerAreaName,
      AreaId: customerAreaId,
      flag: "test",
    })
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.data.p_out_mssg_flg === "S") {
          setAPIResponseTitle("Success");
          setAPIResponseContent(response.data.p_out_mssg);
          setalert(true);
          setDialogSubTitle("Information Updated Successfully");
          setDialogContentTitle("SUCCESS");
        } else {
          setAPIResponseTitle("Error");
          setAPIResponseContent(response.data.p_out_mssg);
          setDialogSubTitle("Unable to Update");
          setDialogContentTitle("");
          setalert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDropDownChange = (e) => {
    console.log(e.target.value);
    setCustomerAreaId(e.target.value);
  };

  return (
    <>
      <Header name={t("Editcustomer_lbl_Header")} link={"/collectPayment"} />
      <div className="edit-customer-container">
        <div className="edit-customer-form">
          <div className="edit-customer-form-row">
            <div className="edit-customer-form-label">
              {t("Editcustomer_lbl_Customer_Name")}
            </div>
            <input
              className="edit-customer-form-input"
              type="text"
              placeholder="Enter the Customer "
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>
          <div className="edit-customer-form-row">
            <div className="edit-customer-form-label">
              {t("Editcustomer_lbl_Customer_Phone")}
            </div>
            <input
              className="edit-customer-form-input"
              type="text"
              readOnly={isPhoneReadOnly}
              placeholder="Enter Customer PhNO"
              value={customerPhone}
              onChange={(e) => setCustomerPhone(e.target.value)}
            />
          </div>
          <div className="edit-customer-form-row">
            <div className="edit-customer-form-label">
              {t("Editcustomer_lbl_Customer_Address")}
            </div>
            <input
              className="edit-customer-form-input"
              type="text"
              placeholder="Enter Customer Address"
              value={customerAddress}
              onChange={(e) => setCustomerAddress(e.target.value)}
            />
          </div>
          <div className="edit-customer-form-row">
            <div className="edit-customer-form-label">
              {t("Editcustomer_lbl_Customer_Area")}
            </div>
            <select
              className="edit-customer-form-input"
              name="area"
              id="area"
              value={customerAreaId}
              onChange={handleDropDownChange}
            >
              {/* <option value="ALL">ALL</option> */}
              <DropDownArea />
            </select>
          </div>
          <button className="edit-customer-button" onClick={handleAPI}>
            {t("Editcustomer_lbl_submitbtn")}
          </button>
        </div>
        <div>
          {isLoading && (
            <ReactLoading
              type={"bars"}
              color={"#0090da"}
              height={75}
              width={75}
            />
          )}
        </div>
      </div>
      <MyDialog_prop
        open={alert}
        onClose={dismiss_dialog}
        title={DialogContentTitle}
        contentText={DialogSubTitle}
      />
      {/*<Dialog
        open={alert}
        onClose={() => setalert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontFamily: "Noto Sans" }}
        >
          {apiResponseTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Noto Sans",
              color: "black",
              border: "none",
              margin: "10px",
              padding: "5px",
            }}
          >
            {apiResponseContent}
          </DialogContentText>
        </DialogContent>
      </Dialog>*/}
    </>
  );
}
