// FloatingButton.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/FloatingButton.css';

const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigation = useNavigate();

  // Add an event listener to handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      // Adjust the scroll threshold as needed
      const scrollThreshold = 200;
      const currentScrollY = window.scrollY;

      if (currentScrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // You can add your button icon and functionality here
  const handleClick = () => {
    // Add your button click logic
    navigation("/addcustomer");
  };

  return (
    // <div className={`floating-button ${isVisible ? 'visible' : 'hidden'}`} onClick={handleClick}>
    <div className="floating-button" onClick={handleClick}>
      ✚
    </div>
  );
};

export default FloatingButton;
