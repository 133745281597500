import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { TbPlus } from "react-icons/tb";
import { AiOutlineMinus } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import API from "../services/API";
import useGeoLocation from "../components/useGeoLocation";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import { FaAngleLeft } from "react-icons/fa";
import MyDialog from "../components/MyDialog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import "../css/CollectPayment.css";
import "../css/global.css";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";

export default function CollectPayment() {
  const navigate = useNavigate();
  const customer = useLocation();
  const { t } = useTranslation();
  const [stbOpen, setstbOpen] = useState(false);
  const [cusOpen, setcusOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [alert, setalert] = useState(false);
  const [alertDialog, setalertDialog] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const customerID = customer.state;
  const [isLoading, setIsLoading] = useState(false);
  const [collect, setCollect] = useState(false);
  const [DialogOpen, setDialogOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [amountpayeditable, setamountpayeditable] = useState(null);
  const [payment_comment, setpayment_comment] = useState(null);
  const [selectedValue, setSelectedValue] = useState("1");
  const [totalPay, settotalAmount] = useState("");
  const [DialogContentTitle, setDialogContentTitle] = useState("");
  const [DialogSubTitle, setDialogSubTitle] = useState("");
  const [paymentresponse, setpaymentresponse] = useState(null);
  const [paymentMobileNumber, setPaymentMobileNumber] = useState(null);
  const [isPhoneReadOnly, setPhoneReadOnly] = useState(true);
  const [isAmountReadOnly, setAmountReadOnly] = useState(false);
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  console.log("user");
  console.log(user);
  useEffect(() => {
    if (!customerID) {
      navigate("/customer");
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const data = {
      customerId: customerID ? customerID.customerId : "",
      operatorId: user.operatorId,
      agentId: user.agentId,
    };

    API.getCustomerInfo(data)
      .then((response) => {
        console.log(response);
        setPaymentMobileNumber(response.data.customerDetailsList[0].phone);
        setCustomerDetails(response.data.customerDetailsList[0]);
        settotalAmount(response.data.customerDetailsList[0].totalPayableAmount);
        const tpa = parseFloat(
          response.data.customerDetailsList[0].totalPayableAmount
        );
        if (tpa > 0) {
          setamountpayeditable(tpa);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        if (user.techFlag === "N") {
          setPhoneReadOnly(false);
        } else {
          setPhoneReadOnly(true);
        }
        if (user.Amount_lock === "Y") {
          setAmountReadOnly(false);
        } else {
          setAmountReadOnly(true);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  if (
    customerDetails !== undefined ||
    customerDetails !== null ||
    customerDetails.length !== 0
  ) {
    localStorage.setItem("stb", customerDetails.stbNumber);
    localStorage.setItem("vc", customerDetails.vcNumber);
    localStorage.setItem("customerDetails", JSON.stringify(customerDetails));
    // console.log(customerDetails)
  }

  const stbOptions_0 = [
    customerDetails.stb_count > 1
      ? {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/Renewal_STBListing",
          state: customerDetails,
        }
      : {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/renewChannel",
          state: customerDetails,
        },
    customerDetails.stb_count > 1
      ? {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/ADDSTBListing",
          state: customerDetails,
        }
      : {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/addChannel",
          state: customerDetails,
        },
  ];
  const stbOptions_8 = [
    {
      id: 1,
      name: t("Collectpayment_stb_import_packs"),
      imgUrl: "import_packs.png",
    },
    customerDetails.stb_count > 1
      ? {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/Renewal_STBListing",
          state: customerDetails,
        }
      : {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/renewChannel",
          state: customerDetails,
        },
    customerDetails.stb_count > 1
      ? {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/ADDSTBListing",
          state: customerDetails,
        }
      : {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/addChannel",
          state: customerDetails,
        },

    {
      id: 4,
      name: t("Collectpayment_stb_Retrack"),
      imgUrl: "sync_data.png",
    },
    {
      id: 5,
      name: t("Collectpayment_stb_resend_request"),
      imgUrl: "resend_request.png",
    },
  ];
  const stbOptions_7 = [
    {
      id: 1,
      name: t("Collectpayment_stb_import_packs"),
      imgUrl: "import_packs.png",
    },
    customerDetails.stb_count > 1
      ? {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/Renewal_STBListing",
          state: customerDetails,
        }
      : {
          id: 2,
          name: t("Collectpayment_stb_Renew_stb"),
          imgUrl: "renew_stb.png",
          toLink: "/customer/collectPayment/renewChannel",
          state: customerDetails,
        },
    customerDetails.stb_count > 1
      ? {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/ADDSTBListing",
          state: customerDetails,
        }
      : {
          id: 3,
          name: t("Collectpayment_stb_Add_Channel"),
          imgUrl: "add_channel.png",
          toLink: "/customer/collectPayment/addChannel",
          state: customerDetails,
        },

    {
      id: 4,
      name: t("Collectpayment_stb_Retrack"),
      imgUrl: "sync_data.png",
    },
    {
      id: 5,
      name: t("Collectpayment_stb_resend_request"),
      imgUrl: "resend_request.png",
    },
  ];
  // let newar = [];
  // newar = [...newar, stbOptions];
  //console.log(newar);
  const CusOptions = [
    {
      id: 1,
      name: t("Collectpayment_cust_edit_customer"),
      imgUrl: "edit_customer.png",
      toLink: "/customer/collectPayment/editCustomer",
      state: customerDetails,
    },
    customerDetails.stb_count > 1
      ? {
          id: 2,
          name: t("Collectpayment_cust_edit_stb"),
          imgUrl: "edit_stb.png",
          // toLink: "/customer/collectPayment/editSetTopBox",
          toLink: "/customer/collectPayment/STBListing",
          state: customerDetails,
        }
      : {
          id: 2,
          name: t("Collectpayment_cust_edit_stb"),
          imgUrl: "edit_stb.png",
          toLink: "/customer/collectPayment/EditSingleBox",
          // toLink: "/customer/collectPayment/STBListing",
          state: customerDetails,
        },

    {
      id: 3,
      name: t("Collectpayment_cust_send_payment_link"),
      imgUrl: "send_payment.png",
    },
    {
      id: 4,
      name: t("Collectpayment_cust_complaints"),
      imgUrl: "complaints.png",
      toLink: "/customer/collectPayment/registerComplaints",
      state: customerDetails,
    },
    {
      id: 5,
      name: t("Collectpayment_cust_history"),
      imgUrl: "history_payment.png",
      toLink: "/customer/collectPayment/history",
      state: customerDetails,
    },
    {
      id: 6,
      name: t("Collectpayment_cust_update_location"),
      imgUrl: "update_location.png",
    },
    /*  {
      id: 7,
      name: t("Collectpayment_cust_print_last_bill"),
      imgUrl: "print_bill.png",
    },*/
    {
      id: 8,
      name: t("Collectpayment_cust_locate_address"),
      imgUrl: "location_collect.png",
    },
    {
      id: 9,
      name: t("Collectpayment_cust_record_visit"),
      imgUrl: "record_visit.png",
      toLink: "/customer/collectPayment/recordVisit",
    },
  ];

  // window.addEventListener("popstate", () => {
  //     window.location.href = ("/customer");
  // })

  const send_payment_link = () => {
    setIsLoading(true);
    console.log(btoa(customerID.customerId));
    const data = {
      phone: customerDetails.phone,
      Network: user.operatorName,
      template: btoa(customerID.customerId),
    };
    API.cashfreepaymentlink(data)
      .then(() => {
        setDialogContentTitle("Payment link Status");
        setDialogSubTitle("Payment Link Sent is Successfull");
        setIsLoading(false);
        setDialogOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGeoLocation = () => {
    setalert(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.error(error);
      },
      {
        timeout: 2000,
        maximumAge: 20000,
        enableHighAccuracy: true,
      }
    );
  };
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const payment_api_alert = () => {
    setalertDialog(true);
  };
  const payment_api = () => {
    setalertDialog(false);
    if (parseFloat(amountpayeditable) > 0) {
      setIsLoading(true);
      let newDate = new Date();
      let getYear = newDate.getFullYear().toString();
      let getMonth = newDate.getMonth().toString();
      let getDate = newDate.getDate().toString();
      let getMSec = newDate.getMilliseconds().toString();
      let combineDate = getYear + getMonth + getDate + getMSec;
      const tran_id =
        "9" + combineDate + user.agentId + user.operatorId + user.operatorId;
      const rembal = parseFloat(totalPay) - parseFloat(amountpayeditable);
      if (expiryDate === null) {
        const data = {
          //pre_end_date: expiryDate,
          operator_id: user.operatorId,
          transactionId: tran_id,
          customerId: customerID.customerId,
          collectedAmount: amountpayeditable,
          phone: paymentMobileNumber,
          paymentMode: selectedValue,
          collectionTimestamp: moment(
            new Date().toLocaleString(),
            "M/D/YYYY, h:mm:ss A"
          ).format("YYYY-MM-DD HH:mm:ss"),
          agentID: user.agentId,
          totalPayableAmount: rembal,
          remarks: payment_comment,
        };
        console.log(JSON.stringify(data));
        API.collectbill(data)
          .then((response) => {
            console.log(response.data.result);
            if (response.data.result === "SUCCESS") {
              //
              if (user.operatorSendSMS === "Y") {
                const data = {
                  tran_amount: amountpayeditable,
                  due_amnt: rembal,
                  company_name: user.operatorName,
                  NAME: customerDetails.customerName,
                  mobile_number: paymentMobileNumber,
                  message_id: "2",
                };
                API.COLLECT_SMS1(data)
                  .then(() => {
                    setDialogContentTitle("Payment Response");
                    setDialogSubTitle("Payment is Successfull");
                    setIsLoading(false);
                    setDialogOpen(true);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                console.log(user.operatorSendSMS);

                setDialogContentTitle("Payment Response");
                setDialogSubTitle("Payment is Successfull");
                setIsLoading(false);
                setDialogOpen(true);
              }
            } else {
              setDialogSubTitle("Unable to Update Payment");
              toast.error("Unable to Update the Payment");
              setDialogOpen(true);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const data = {
          operator_id: user.operatorId,
          pre_end_date: expiryDate,
          transactionId: tran_id,
          customerId: customerID.customerId,
          collectedAmount: amountpayeditable,
          phone: paymentMobileNumber,
          paymentMode: selectedValue,
          collectionTimestamp: moment(
            new Date().toLocaleString(),
            "M/D/YYYY, h:mm:ss A"
          ).format("YYYY-MM-DD HH:mm:ss"),
          agentID: user.agentId,
          totalPayableAmount: rembal,
          remarks: payment_comment,
        };
        //  console.log(JSON.stringify(data));
        API.collectbill(data)
          .then((response) => {
            console.log(response.data.result);
            if (response.data.result === "SUCCESS") {
              if (user.operatorSendSMS === "Y") {
                const data = {
                  tran_amount: amountpayeditable,
                  due_amnt: rembal,
                  company_name: user.operatorName,
                  NAME: customerDetails.customerName,
                  mobile_number: paymentMobileNumber,
                  message_id: "2",
                };
                API.COLLECT_SMS1(data)
                  .then(() => {
                    setDialogContentTitle("Payment Response");
                    setDialogSubTitle("Payment is Successfull");
                    setIsLoading(false);
                    setDialogOpen(true);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                console.log(user.operatorSendSMS);

                setDialogContentTitle("Payment Response");
                setDialogSubTitle("Payment is Successfull");
                setIsLoading(false);
                setDialogOpen(true);
              }
            } else {
              setDialogOpen(true);
              setDialogSubTitle("Unable to Update Payment");

              setIsLoading(false);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
       setDialogOpen(true);
       setDialogContentTitle("Error");
       setDialogSubTitle("Amount Must be Greater than Rs.1")
    }

  };

  /*  const handlePaymentLinkDialogClose = () => {
     setPaymentLink(false);
   };*/

  const updateLocation = () => {
    setIsLoading(true);
    const latilong = latitude + "," + longitude;
    const data = {
      cust_num: customerID.customerId,
      latilong: latilong,
      operatorId: user.operatorId,
    };
    API.updateCustomerlocation(data)
      .then((response) => {
        if (response.data.p_out_mssg_flg === "S") {
          setIsLoading(false);
          console.log(response);
          setalert(false);
        } else {
          setIsLoading(false);
          console.log(response);
          setalert(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function dateUpdate(date) {
    setExpiryDate(date);
  }

  return (
    <>
      <div className="top-header">
        <div style={{ height: "2vh" }}>
          <FaAngleLeft
            onClick={() => {
              navigate(-1);
            }}
            style={{
              color: "white",
              height: "3vh",
              position: "absolute",
              left: "5vw",
            }}
          />
        </div>
      </div>
      <LoadingOverlay active={isLoading} spinner text="Please Wait...">
        <div className="header-blue1">
          <table className="headerTable">
            <tr className="t-row-1">
              <td className="t-col-1">{t("Collectpayment_customer_name")}</td>
              <td className="t-col-2">:</td>
              <td className="owner-name-data">
                {customerDetails ? customerDetails.customerName : "--"}
              </td>
            </tr>
            <tr className="t-row-2">
              <td className="t-col-1">{t("Collectpayment_customer_id")}</td>
              <td className="t-col-2">:</td>
              <td className="t-col-3">
                {customerDetails ? customerDetails.managedCustomerId : "--"}
              </td>
            </tr>
            <tr className="t-row-3">
              <td className="t-col-1">{t("Collectpayment_VC_No")}</td>
              <td className="t-col-2">:</td>
              <td className="stb_fields">
                {customerDetails ? customerDetails.vcNumber : "--"}
              </td>
            </tr>
            <tr className="t-row-4">
              <td className="t-col-1">{t("Collectpayment_STB_No")}</td>
              <td className="t-col-2">:</td>
              <td className="stb_fields">
                {customerDetails ? customerDetails.stbNumber : "--"}
              </td>
            </tr>
          </table>
        </div>
        <div className="PaymentContainer">
          <div className="tableContainer">
            <table className="bodyTable1">
              <tr>
                <td className="b-col-1 lS">
                  {t("Collectpayment_Expiry_date")}
                </td>
                <td className="b-col-2 lS">:</td>
                <td
                  className="b-col-3 lS"
                  style={{ color: "#DC1515", fontWeight: "700" }}
                >
                  {customerDetails && customerDetails.endDate
                    ? moment(customerDetails.endDate).format("DD MMMM YYYY")
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">{t("Collectpayment_Last_Paid")}</td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹ {customerDetails ? customerDetails.lastCollectedAmount : ""}
                </td>
              </tr>
            </table>
          </div>
          <div className="tableContainer">
            <table className="bodyTable1">
              <tr>
                <td className="b-col-1 lS">{t("Collectpayment_Base_amt")}</td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹ {customerDetails ? customerDetails.baseBillAmount : ""}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">{t("Collectpayment_Tax")}</td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹ {customerDetails ? customerDetails.taxBillAmount : ""}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">{t("Collectpayment_Pack_amt")}</td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹{" "}
                  {customerDetails
                    ? Number(customerDetails.baseBillAmount) +
                      Number(customerDetails.taxBillAmount)
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">
                  {t("Collectpayment_previous_balance")}
                </td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹{" "}
                  {customerDetails ? customerDetails.previousPendingAmount : ""}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">
                  {t("Collectpayment_amount_adjusted")}
                </td>
                <td className="b-col-2 lS">:</td>
                <td className="b-col-3 lS">
                  ₹ {customerDetails.permanent_bal}
                </td>
              </tr>
              <tr>
                <td className="b-col-1 lS">{t("Collectpayment_Total_Pay")}</td>
                <td className="b-col-2 lS">:</td>
                <td
                  className="b-col-3 lS"
                  style={{ color: "#DC1515", fontWeight: "700" }}
                >
                  ₹ {customerDetails ? customerDetails.totalPayableAmount : ""}
                </td>
              </tr>
            </table>
          </div>
          <div className="tableContainer">
            <input
              type={"number"}
              value={amountpayeditable}
              readOnly={isAmountReadOnly}
              placeholder="Enter a Amount"
              onChange={(e) => {
                setamountpayeditable(e.target.value);
              }}
            />
            <input
              readOnly={isPhoneReadOnly}
              type={"text"}
              value={paymentMobileNumber}
              onChange={(e) => {
                setPaymentMobileNumber(e.target.value);
              }}
              placeholder="Customer Mobile Number"
            />
            <input
              type={"text"}
              onChange={(e) => {
                setpayment_comment(e.target.value);
              }}
              placeholder="Write Your Comments"
            />
            <select value={selectedValue} onChange={handleDropdownChange}>
              <option value="1">CASH</option>
              <option value="3">Online</option>
            </select>

            {/* <DatePicker format="dd-MM-yyyy" /> */}
            <input
              type={"date"}
              placeholder="Expiry Date"
              onChange={(e) => dateUpdate(e.target.value)}
            />
            {/* <input
                            placeholder="Next Expiry Date"
                            type="text"
                            onfocus="(this.type='date')"
                            onblur="(this.type='text')"
                        /> */}

            <div className="collectBtn-div">
              <button
                className="collectbtnPayment"
                type="submit"
                onClick={payment_api_alert}
              >
                {t("Collectpayment_button_Collect_Payment")}
              </button>
            </div>
          </div>
          <div className="CollapseContainer">
            <div style={{ width: "90%" }}>
              <Collapsible
                trigger={[
                  "STB Integrated Options",
                  <TbPlus style={{ width: "30px", height: "30px" }} />,
                ]}
                triggerClassName={"Collapsible__trigger1"}
                triggerOpenedClassName={"Collapsible__trigger1Open"}
                triggerWhenOpen={[
                  "STB Integrated Options",
                  <AiOutlineMinus style={{ width: "30px", height: "30px" }} />,
                ]}
                //   isOpen={stbOpen}
                //   onOpen={()=>(setcusOpen(false))}
                open={stbOpen}
                handleTriggerClick={() => {
                  setstbOpen(!stbOpen);
                  setcusOpen(false);
                }}
                transitionCloseTime={"300"}
              >
                <div className="STBOptionsContainer">
                  {user.mso_id === 7
                    ? stbOptions_7.map((option) => {
                        return (
                          <div
                            className="STBEachOption"
                            id={option.id}
                            onClick={() =>
                              navigate(option.toLink, {
                                state: option.state,
                              })
                            }
                          >
                            <img
                              src={require("../assets/" + option.imgUrl)}
                              width={"45%"}
                            />
                            <p>{option.name}</p>
                          </div>
                        );
                      })
                    : user.mso_id === 8
                    ? stbOptions_8.map((option) => {
                        return (
                          <div
                            className="STBEachOption"
                            id={option.id}
                            onClick={() =>
                              navigate(option.toLink, {
                                state: option.state,
                              })
                            }
                          >
                            <img
                              src={require("../assets/" + option.imgUrl)}
                              width={"45%"}
                            />
                            <p>{option.name}</p>
                          </div>
                        );
                      })
                    : stbOptions_0.map((option) => {
                        return (
                          <div
                            className="STBEachOption"
                            id={option.id}
                            onClick={() =>
                              navigate(option.toLink, {
                                state: option.state,
                              })
                            }
                          >
                            <img
                              src={require("../assets/" + option.imgUrl)}
                              width={"45%"}
                            />
                            <p>{option.name}</p>
                          </div>
                        );
                      })}
                </div>
              </Collapsible>
            </div>
            <div style={{ width: "90%", marginBottom: "2vh" }}>
              <Collapsible
                trigger={[
                  "Customer Related Operations",
                  <TbPlus style={{ width: "30px", height: "30px" }} />,
                ]}
                triggerClassName={"Collapsible__trigger2"}
                triggerOpenedClassName={"Collapsible__trigger2Open"}
                triggerWhenOpen={[
                  "Customer Related Operations",
                  <AiOutlineMinus style={{ width: "30px", height: "30px" }} />,
                ]}
                transitionCloseTime={"200"}
                //   isOpen={cusOpen}
                //   onOpen={()=>setstbOpen(false)}
                open={cusOpen}
                handleTriggerClick={() => {
                  setcusOpen(!cusOpen);
                  setstbOpen(false);
                }}
              >
                <div className="STBOptionsContainer">
                  {CusOptions.map((option) => {
                    return (
                      <div
                        className="STBEachOption"
                        id={option.id}
                        onClick={() => {
                          if (option.id === 6 && customerID) {
                            handleGeoLocation();
                            return;
                          }
                          if (option.id === 3 && customerID) {
                            send_payment_link();
                            return;
                          }
                          // navigate only if customerID is present
                          if (customerID) {
                            navigate(option.toLink, {
                              state: option.state,
                            });
                          }
                        }}
                      >
                        <img
                          src={require("../assets/" + option.imgUrl)}
                          width={"45%"}
                        />
                        <p>{option.name}</p>
                      </div>
                    );
                  })}
                </div>
              </Collapsible>
            </div>
          </div>
        </div>
        <Dialog
          open={alert}
          onClose={() => setalert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontFamily: "Noto Sans" }}
          >
            Update Location
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                color: "black",
                border: "none",
                margin: "10px",
                padding: "5px",
              }}
            >
              <div>
                <p>Latitude : {latitude}</p>
                <p>Longitude :{longitude}</p>
              </div>
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                background: "var(--primay-app-color)",
                color: "white",
                border: "none",
                borderRadius: "20px",
                margin: "15px",
                padding: "10px",
              }}
              onClick={updateLocation}
            >
              SUBMIT DIRECTION
            </DialogContentText>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isLoading && <Spinner animation="border" variant="info" />}
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={alertDialog}
          onClose={() => setalertDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontFamily: "Noto Sans" }}
          >
            Confirmation
          </DialogTitle>
          <DialogContent >
            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                color: "black",
                border: "none",
                margin: "10px",
                padding: "5px",
              }}
            >
              <div>{"Are You Sure You Want to Collect!"}</div>
            </DialogContentText>
            <div style={{display: "flex",flexDirection: "row",justifyContent: "center"}}>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Noto Sans",
                  background: "var(--primay-app-color)",
                  color: "white",
                  border: "none",
                  borderRadius: "20px",
                  margin: "15px",
                  padding: "10px",
                }}
                onClick={payment_api}
              >
                YES
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Noto Sans",
                  background: "var(--primay-app-color)",
                  color: "white",
                  border: "none",
                  borderRadius: "20px",
                  margin: "15px",
                  padding: "10px",
                }}
                onClick={() => setalertDialog(false)}
              >
                NO
              </DialogContentText>
            </div>
          </DialogContent>
        </Dialog>

        <MyDialog
          open={DialogOpen}
          onClose={() => setDialogOpen(false)}
          title={DialogContentTitle}
          contentText={DialogSubTitle}
        />
      </LoadingOverlay>
    </>
  );
}
