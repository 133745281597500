import React, { useEffect, useState } from "react";
import Header from "../components/header";
import "../css/EditSetTopBox.css";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import API from "../services/API";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditSetTopBox() {
  const navigate = useNavigate();
  const location = useLocation();
  const [customer, setCustomer] = useState(
    JSON.parse(localStorage.getItem("customerDetails"))
  );
  const gotNavigatestate = location.state;
  const { t } = useTranslation();
  const [stb, setStb] = useState("");
  const [stb_id, setstb_id] = useState("");
  const [vc, setVc] = useState("");
  const [endDate, setEndDate] = useState("");
  const [balance, setBalance] = useState(customer.totalPayableAmount || 0);
  const [status, setStatus] = useState("");
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  

  useEffect(() => {
    if (customer) {
      localStorage.setItem("customer", JSON.stringify(customer));
    }
  }, [customer]);

  useEffect(() => {
    if (gotNavigatestate) {
      console.log(gotNavigatestate);
      setstb_id(gotNavigatestate.STB_ID);
      setStb(gotNavigatestate.STB_NUMBER);
      setVc(gotNavigatestate.VC_NUMBER);
      setEndDate(gotNavigatestate.PRE_END_DATE);
      //  console.log("State Value's : ", gotNavigatestate);
      if (gotNavigatestate.SERVICE_STATUS === "Active") {
        setStatus("Active");
      } else if (gotNavigatestate.SERVICE_STATUS === "Suspended") {
        setStatus("Suspended");
      } else {
        setStatus("Cancelled");
      }
    }
  }, [gotNavigatestate]);

  const handleEditSTBSubmit = () => {
    if (stb !== "" && vc !== "" && endDate !== "" && balance !== "") {
      console.log(
        JSON.stringify({
          agent_id: user.agentId,
          customer_num: customer.customerId,
          operator_id: user.operatorId,
          vc_number: vc,
          stb_number: stb,
          status: status,
          balance: balance,
          stb_id: stb_id,
          flag: "S",
        })
      );
      API.editSTBInfo({
        agent_id: user.agentId,
        customer_num: customer.customerId,
        operator_id: user.operatorId,
        vc_number: vc,
        stb_number: stb,
        status: status,
        balance: balance,
        stb_id: stb_id,
        flag: "S",
      })
        .then((res) => {
          console.log(res);
          if (res.data.p_out_mssg_flg === "S") {
            toast.success("STB Updated Successfully");
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          } else {
            console.log("STB Update Failed");
            toast.error("STB Update Failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const headerprops = {
    text: "Edit SetTop Box",
    height: "10vh",
  };

  return (
    <>
      <Header name={t("Editsettopbox_lbl_Header")} />
      <div className="edit-settopbox-container">
        <div className="edit-settopbox-form">
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_stb_no")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the STB no"
                value={stb}
                onChange={(e) => {
                  setStb(e.target.value);
                }}
              />
            </div>
            <img
              onClick={() =>
                navigate(
                  "/customer/collectPayment/editSetTopBox/barCodeScanner?field=stb"
                )
              }
              className="edit-settopbox-form-input-icon"
              src={require("../assets/barcodeicon.png")}
              style={{ width: "70px", height: "40px" }}
              alt="barcode"
            />
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_vc_no")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the VC no"
                value={vc}
                onChange={(e) => {
                  setVc(e.target.value);
                }}
              />
            </div>
            <img
              onClick={() =>
                navigate(
                  "/customer/collectPayment/editSetTopBox/barCodeScanner?field=vc"
                )
              }
              className="edit-settopbox-form-input-icon"
              src={require("../assets/barcodeicon.png")}
              style={{ width: "70px", height: "40px" }}
              alt="barcode"
            />
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_enddate")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                value={endDate}
                readOnly
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_balance")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the Balance"
                value={balance}
                readOnly
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_status")}
              </div>
              <select
                className="edit-settopbox-form-input"
                name="status"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">{t("Editsettopbox_drop_active")}</option>
                <option value="Suspended">
                  {t("Editsettopbox_drop_Temporary")}
                </option>
                <option value="Cancelled">
                  {t("Editsettopbox_drop_Permanent")}
                </option>
              </select>
            </div>
          </div>
          <button
            className="edit-settopbox-form-button"
            onClick={handleEditSTBSubmit}
          >
            SUBMIT
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
