import React, { useEffect, useState } from "react";
import Header from "../components/header";
import "../css/EditSetTopBox.css";
import { json, useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import moment from "moment";
import API from "../services/API";
import LoadingOverlay from "react-loading-overlay";
import "react-toastify/dist/ReactToastify.css";

export default function AddCustomer() {
  const navigate = useNavigate();
  // const [formData, setFormData] = useState({
  //   custName: '',
  //   custID: '',
  //   custArea: '',
  //   custPhone: '',
  //   custAdd: '',
  //   custOpBalance: '',
  //   custActivationDate: '',
  //   stbNo: '',
  //   vcNo: '',
  //   stbModel: ''
  // });
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [status, setStatus] = useState("");
  const [sOpen, setSOpen] = useState(false);
  const [fOpen, setFOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [area, setArea] = useState([])
  const [selectedPacks, setSelectedPacks] = useState([]);
  const [availablePacks, setAvailablePacks] = useState([]);
  // const [filteredPacks, setFilteredPacks] = useState(availablePacks);
  const [packType, setPackType] = useState('B');

  //Form values
  const [custName, setCustName] = useState("");
  const [custID, setCustID] = useState("");
  const [custArea, setCustArea] = useState("");
  const [custPhone, setCustPhone] = useState("");
  const [custOpBalance, setCustOpBalance] = useState("");
  const [custActDate, setCustActDate] = useState("");
  const [custStbNo, setCustStbNo] = useState("");
  const [custVcNo, setCustVcNo] = useState("");
  const [custStbModel, setCustStbModel] = useState("");
  const [custStbActDate, setCustStbActDate] = useState("");
  const [custAddress, setCustAddress] = useState("");
  const [custAgreementNo, setCustAgreementNo] = useState("");


  useEffect(() => {
    fetchPackDetails();
    fetchAreas();
    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i);
    //   const value = localStorage.getItem(key);
    //   console.log(i," -- ");
    //   console.log(`${key}: ${value}`);
    // }
  },[])

  const fetchPackDetails = () => {
    setIsLoading(true);
    const homedata = localStorage.getItem('homedata');
    const LocalData = JSON.parse(homedata);
    const Operator_ID = LocalData.OPERATOR_ID;
    let data = {
      operatorId: Operator_ID
    }

    API.fetchPackages(data).then((response) => {
      setIsLoading(false);
      setAvailablePacks(response.data.all_packs)
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Error encountered while fetching packages in AddCustomer page (",error,")");
    })
  }

const fetchAreas = () => {
  setIsLoading(true);
  const homedata = localStorage.getItem('homedata');
  const LocalData = JSON.parse(homedata);
  const Operator_ID = LocalData.OPERATOR_ID;
  let data = {
    operatorId: Operator_ID
  }

  API.fetchArea(data).then((response) => {
    setIsLoading(false);
    setArea(response.data.all_areas);
  })
  .catch((error) => {
    setIsLoading(false);
    console.error("Error encountered while fetching area in AddCustomer page (",error,")");
  })
 }

 const createCustomer = () => {
  setIsLoading1(true);
  const homedata = localStorage.getItem('homedata');
  const LocalData = JSON.parse(homedata);
  // console.log("Home Data :",LocalData);
  const Agent_ID = LocalData.agent_id;
  const Operator_ID = LocalData.OPERATOR_ID;
  let BasePrice = 0;
  let TotalTax = 0;
  let TotalAmt = 0; 
  const SelectedPackArray = selectedPacks.map(item => { 
    BasePrice = Number(BasePrice)+Number(item.packBasePrice);
    TotalTax = Number(TotalTax)+Number(item.packTaxPrice);
    TotalAmt = Number(TotalAmt)+Number(item.packBasePrice)+Number(item.packTaxPrice)+Number(item.SERVICE_TAX);
    return {
        subs_plan_id: item.packId,
        pack_name: item.packName,
        packtype: item.packType,
        subs_prc: item.packBasePrice,
        tax_amnt: item.packTaxPrice,
        subs_grnd_tot_prc: item.packTotalPrice,
        pkgcode: item.pkgcode,
        pkgtype: item.packType,
        Channel_Type: item.Channel_Type,
        SERVICE_TAX: item.SERVICE_TAX,
        plan_disable_flag: "N"
    };
  });
  let data = {
      records: [
          {
              customer_details: {
              agent_id: Agent_ID,
                  customer_name: custName, 
                  alias_name: "NULL",
                  customer_id: custID,
                  area_name: custArea,
                  agent_name: "NULL",
                  phone: custPhone,
                  address: custAddress,
                  email: "NULL",
                  previous_due: "NULL",
                  activation_date: custActDate,
                  agreement_number: custAgreementNo,
                  customer_category: "NULL",
                  gst_number: "NULL",
                  area_id: custArea,
                  operator_id: Operator_ID,
                  service_status: "Active",
                  dmlType: "I",
                  remarks: "NULL",
                  created_by: "NULL",
                  created_on: "NULL",
                  modified_by: "NULL",
                  modified_on: "NULL",
                  comments: "NULL",
                  advance_amount: custOpBalance
              },
              subscription_details: [
                  {
                      rownum: "NULL",
                      total_tax: TotalTax,
                      base_price: BasePrice,
                      stb_number: custStbNo,
                      total_amount: TotalAmt,
                      subs_plan_id: "NULL",
                      vc_number: custVcNo,
                      service_status: "Active",
                      stb_model_number: custStbModel,
                      stb_serial_number: "",
                      stb_activation_date: custStbActDate,
                      stb_id: "NULL",
                      record_type: "N",
                      subscription_disable_flag: "N",
                      pre_start_date: startDate,
                      pre_end_date: endDate,
                      pre_status_flag: "Y",
                      pack_details: SelectedPackArray
                  }
              ]
          }
      ]
  }

  console.log("------------------------Request Body----------------------------");
  // console.log(data);
  console.log(JSON.stringify(data));
  console.log("----------------------------------------------------------------");

  API.createCustomer(data).then((response) => {
    setIsLoading1(false);
    setSOpen(true);
    console.log("Create customer API response :",response);
  })
  .catch((error) => {
    setIsLoading1(false);
    setFOpen(true);
    console.error("Encountered an error while creating customer :",error);
  })
 }

 const submitButton = () => {
  console.log("----------------------------Packs------------------------------");
  console.log(selectedPacks);
  console.log("---------------------------------------------------------------");
  const SelectedPackArray2 = selectedPacks.map(item => { 
    return {
        subs_plan_id: item.packId,
        pack_name: item.packName,
        packtype: item.packType,
        subs_prc: item.packBasePrice,
        tax_amnt: item.packTaxPrice,
        subs_grnd_tot_prc: item.packTotalPrice,
        pkgcode: item.pkgcode,
        pkgtype: item.packType,
        Channel_Type: item.Channel_Type,
        SERVICE_TAX: item.SERVICE_TAX,
        plan_disable_flag: "N"
    };
  });
  console.log("------------------Converted Packs------------------------------");
  console.log(SelectedPackArray2);
  console.log("---------------------------------------------------------------");
 }

  //----------------Page 3 Code---------------------------
  const handleStartDateChange = (e) => {
    console.log("Start Date Modified:",e.target.value);
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    console.log("Start End Modified:",e.target.value);
    setEndDate(e.target.value);
  };

  const handlePackAdd = (pack) => {
    setSelectedPacks((prevSelectedPacks) => [...prevSelectedPacks, pack]);
    setAvailablePacks((prevAvailablePacks) =>
      prevAvailablePacks.filter((p) => p.packId !== pack.packId)
    );
  };

  const handlePackRemove = (pack) => {
    setSelectedPacks((prevSelectedPacks) =>
      prevSelectedPacks.filter((p) => p.packId !== pack.packId)
    );
    setAvailablePacks((prevAvailablePacks) => [...prevAvailablePacks, pack]);
  };

  const renderPacks = (packs, onButtonClick, buttonLabel, filter = false, pckType = 'B') => {
    return(
      <div style={{display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
        <table style={{width: '100%'}}>
        {packs.map((pack) => (
          <div className="three-settopbox" 
          style={{display: "inline-block", marginLeft: "0px", marginBottom: "10px", width: '100%'}}
          key={pack.packId}>
              <tr style={{width: '100%'}}>
                <td style={{width: '80%'}}>{pack.packName}</td>
                <td style={{width: '20%'}}><button style={{border: '0px white solid', borderRadius: '15px'}} onClick={() => onButtonClick(pack)}>{buttonLabel}</button></td>
              </tr>
          </div>
        ))}
        </table>
      </div>
    );
  }
  
  //--------------End of Page 3 Code-------------------

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({ ...prevData, [name]: value }));
  // };

  // const handleEditSTBSubmit = () => {
  //   console.log("Handel Button Clicked....");
  // };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="edit-settopbox-form">
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Customer Name
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter Customer Name"
                onChange={(e) => {
                  // console.log("Customer Name Changed: ",e.target.value);
                  setCustName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Customer ID
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter Customer ID"
                onChange={(e) => {
                  // console.log("Customer ID Changed: ",e.target.value);
                  setCustID(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Area
              </div>
              <select
                className="edit-settopbox-form-input"
                name="status"
                id="status"
                onChange={(e) => {
                  // console.log("Customer Area Changed: ",e.target.value);
                  setCustArea(e.target.value);
                }}
              >
                <option value="" selected>Select Area</option>
                {
                  area.map((a) => (
                      <option value={a.id}>{a.area_name}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Customer Phone
              </div>
              <input
                className="edit-settopbox-form-input"
                type="number"
                placeholder="Enter Customer Phone"
                onChange={(e) => {
                  // console.log("Customer Phone Changed: ",e.target.value);
                  setCustPhone(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Customer Address
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter Customer Address"
                onChange={(e) => {
                  // console.log("Customer Address Changed: ",e.target.value);
                  setCustAddress(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Agreement Number
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter Agreement Number"
                onChange={(e) => {
                  // console.log("Customer Agreement No: ",e.target.value);
                  setCustAgreementNo(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Opening Balance
              </div>
              <input
                className="edit-settopbox-form-input"
                type="number"
                placeholder="Enter the Opening Balance"
                onChange={(e) => {
                  // console.log("Customer Opening Balance: ",e.target.value);
                  setCustOpBalance(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                Activation Date
              </div>
              <input
                className="edit-settopbox-form-input"
                type="date"
                onChange={(e) => {
                  // console.log("Customer Activation Date: ",e.target.value);
                  setCustActDate(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            className="edit-settopbox-form-button"
            onClick={nextStep}
          >
            Next
          </button>
          </div>
        );

      case 2:
        return (
          <div className="edit-settopbox-form">
            <div className="edit-settopbox-form-row">
              <div className="three-settopbox">
                <div className="edit-settopbox-form-label">
                  STB No.
                </div>
                <input
                  className="edit-settopbox-form-input"
                  type="text"
                  placeholder="Enter STB Number"
                  value={custStbNo}
                  onChange={(e) => {
                    // console.log("STB No. :",e.target.value);
                    setCustStbNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="edit-settopbox-form-row">
              <div className="three-settopbox">
                <div className="edit-settopbox-form-label">
                  VC No.
                </div>
                <input
                  className="edit-settopbox-form-input"
                  type="text"
                  placeholder="Enter VC Number"
                  value={custVcNo}
                  onChange={(e) => {
                    // console.log("STB VC No. :",e.target.value);
                    setCustVcNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="edit-settopbox-form-row">
              <div className="three-settopbox">
                <div className="edit-settopbox-form-label">
                  STB Model
                </div>
                <input
                  className="edit-settopbox-form-input"
                  type="text"
                  placeholder="Enter STB Model"
                  onChange={(e) => {
                    // console.log("STB Model :",e.target.value);
                    setCustStbModel(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="edit-settopbox-form-row">
              <div className="three-settopbox">
                <div className="edit-settopbox-form-label">
                  STB Activation Date
                </div>
                <input
                  className="edit-settopbox-form-input"
                  type="date"
                  onChange={(e) => {
                    // console.log("STB Activation Date:",e.target.value);
                    setCustStbActDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <button 
            className="edit-settopbox-form-button"
            onClick={nextStep}>Next</button>
            <button
            className="edit-settopbox-form-button"
            onClick={prevStep}>Previous</button>
          </div>
        );
      case 3:  
        return (
          <LoadingOverlay styles={{position: "fixed"}}  active={isLoading1} spinner text="Loading...">
          <div style={{width: "100%",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
            <div className="edit-settopbox-form">
              <div className="edit-settopbox-form-row">
                <div className="three-settopbox">
                  <div className="edit-settopbox-form-label">Start Date:</div>
                  <input className="edit-settopbox-form-input" type="date" value={startDate} onChange={handleStartDateChange} />
                </div>
              </div>
              <div className="edit-settopbox-form-row">
                <div className="three-settopbox">
                  <div className="edit-settopbox-form-label">End Date:</div>
                  <input className="edit-settopbox-form-input" type="date" value={endDate} onChange={handleEndDateChange} />
                </div>
              </div>
              {/* <button className="edit-settopbox-form-button" onClick={() => alert('Apply Pack')}>Apply Pack</button> */}
            </div>

            <div  className="edit-settopbox-form" style={{maxHeight: "300px", overflow: "scroll", minHeight: "100px"}}>
              <div className="edit-settopbox-form-row"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center", 
                  position: 'sticky', 
                  top: '0', 
                  backgroundColor: 'var(--primay-app-color)'
                }}
              >
                <h6 style={{textAlign: "center", color: 'white', paddingTop: '10px'}}>SELECTED PACKS</h6>
              </div>
              {renderPacks(selectedPacks, handlePackRemove, '🗑️')}
            </div>

            <div  className="edit-settopbox-form" style={{position: 'relative',maxHeight: "400px", overflowX: "hidden", overflowY: 'scroll'}}>
              <div className="edit-settopbox-form-row"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center", 
                  position: 'sticky', 
                  top: '0', 
                  backgroundColor: 'var(--primay-app-color)'
                }}
              >
                <h6 style={{textAlign: "center", color: 'white', paddingTop: '10px'}}>AVAILABLE PACKS</h6>
                <div style={{width: "100%"}}>
                  <select
                    className="edit-settopbox-form-input"
                    style={{width: "95%", border: "0px #000", borderRadius: "15px"}}
                    name="status"
                    id="status"
                    onChange={(e) => {
                      console.log("Customer Area Changed: ",e.target.value);
                      setPackType(e.target.value);
                    }}
                  >
                    <option className="packOption" value="B" selected>Base Pack</option>
                    <option className="packOption" value="C">Pay Channel</option>
                  </select>
                </div>
              </div>
              {renderPacks(availablePacks.filter(pack => pack.pkgtype === packType), handlePackAdd, '➕')}
            </div>
            <button
              className="edit-settopbox-form-button"
              style={{marginBottom: "0px"}}
              onClick={prevStep}>
                Previous
            </button>
            <button
              className="edit-settopbox-form-button"
              onClick={() => {createCustomer()}}>
                Submit
            </button>
            <Dialog
                open={sOpen}
                onClose={() => setSOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ borderRadius: "25px" }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333333",
                    paddingBottom: "10px"
                  }}
                >
                  Successful
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{
                    textAlign: "center"
                  }}>
                    Customer Added successfully.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Noto Sans",
                      background: "var(--primay-app-color)",
                      color: "white",
                      border: "none",
                      borderRadius: "20px",
                      margin: "15px",
                      padding: "10px",
                    }}
                    onClick={ () => { navigate('/customer'); } }
                  >
                    OK
                  </DialogContentText>
                </DialogContent>
          </Dialog>
          <Dialog
                open={fOpen}
                onClose={() => setFOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ borderRadius: "25px" }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333333",
                    paddingBottom: "10px"
                  }}
                >
                  Failed!!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{
                    textAlign: "center"
                  }}>
                    Operation Failed. Please try again.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Noto Sans",
                      background: "var(--primay-app-color)",
                      color: "white",
                      border: "none",
                      borderRadius: "20px",
                      margin: "15px",
                      padding: "10px",
                    }}
                    onClick={ () => { setFOpen(false) } }
                  >
                    OK
                  </DialogContentText>
                </DialogContent>
          </Dialog>
          </div>
          </LoadingOverlay>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header name="Add Customer" />
      <LoadingOverlay styles={{position: "fixed"}}  active={isLoading} spinner text="Loading...">
      <div className="edit-settopbox-container" >
        {renderStep()}
      </div>
      </ LoadingOverlay>
    </>
  );
}
