import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const MyDialog_prop = ({
  open,
  onClose,
  title,
  contentText,
  DialogType,
  src,
}) => {
  const dismissDialog = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={dismissDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ fontFamily: "Noto Sans" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Noto Sans",
            color: "black",
            border: "none",
            margin: "10px",
            padding: "5px",
          }}
        >
          <div>{contentText}</div>
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Noto Sans",
            background: "var(--primay-app-color)",
            color: "white",
            border: "none",
            borderRadius: "20px",
            margin: "15px",
            padding: "10px",
          }}
          onClick={dismissDialog}
        >
          OK
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default MyDialog_prop;
