import React, { useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import "../css/header.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
export default function BarCodeScanner() {
    const [data, setData] = React.useState("Not Found");
    const location = useLocation();

    const navigate = useNavigate();
    const field = new URLSearchParams(location.search).get("field");

    const gotresult = (data) => {
        if (data !== "Not Found" && data !== "undefined" && data !== "") {
            // console.log(data);
            setData(data);
            if (field === "stb") {
                // navigate(
                //     "/customer/collectPayment/editSetTopBox?data=" +
                //         data +
                //         "&field=stb"
                // );
                localStorage.setItem("stb", data);
                navigate(-1)
            } else if (field === "vc") {
                // navigate(
                //     "/customer/collectPayment/editSetTopBox?data=" +
                //         data +
                //         "&field=vc"
                // );
                localStorage.setItem("vc", data);
                navigate(-1)
            }

        }
    };
    // gotresult("112")
   
    return (
        <div className="barcode-container">
            <div
                className="barcode"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                }}
            >
                <BarcodeScannerComponent
                    onUpdate={(err, result) => {
                        if (result) gotresult(result.text);
                        else setData("Not Found");
                    }}
                />
                <p>{data}</p>
            </div>
        </div>
    );
}
