import { useState, useEffect, useRef } from "react";
import { BiMenu } from "react-icons/bi";
import "../css/HamNav.css";
import "../css/global.css";
import { useNavigate } from "react-router-dom";

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);

  let userJson;
  const toggleNavbar = () => setOpen(!open);
  const navigate = useNavigate();
  const user = localStorage.getItem("user") || sessionStorage.getItem("user");
  if (user) {
    userJson = JSON.parse(user);
  }
  console.log(user);
  console.log("user");

  const handleLanguageBar = () => {
    navigate("/select");
    toggleNavbar();
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("username");
    navigate("/");
  };

  let navRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!navRef.current.contains(e.target)) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <BiMenu
        style={{ color: "white", width: "40px", height: "30px" }}
        onClick={toggleNavbar}
      />
      <div className={open ? "inactive" : "active"} ref={navRef}>
        <div className="ham-drawer__header">
          <div className="ham-drawer__space"></div>
          <div className="ham-drawer_user_details">
            <img
              src={require("../assets/profile1.png")}
              className="ham-drawer__profile_img"
              alt="profile_image"
            />
            <p className="ham-drawer__user_name">{userJson.agentName}</p>

            <p className="ham-drawer__user_email">{userJson.operatorName}</p>
            <p className="ham-drawer__user_phone">{userJson.agentPhone}</p>
          </div>
        </div>
        <div className="ham-drawer__body">
          <div className="ham-drawer__body__item" onClick={toggleNavbar}>
            <p className="ham-drawer__body__item__text">Home</p>
            <img
              src={require("../assets/side_arrow.png")}
              className="ham-drawer__body__item__arrow"
            />
          </div>
          <div className="ham-drawer__body__item" onClick={handleLanguageBar}>
            <p className="ham-drawer__body__item__text">Language Preference</p>
            <img
              src={require("../assets/side_arrow.png")}
              className="ham-drawer__body__item__arrow"
            />
          </div>
          <div className="ham-drawer__body__item" onClick={handleLogout}>
            <p className="ham-drawer__body__item__text">Logout</p>
            <img
              src={require("../assets/side_arrow.png")}
              className="ham-drawer__body__item__arrow"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileNavigation;
