import React from "react";
import "../css/AreaWiseReport.css";
import "../css/global.css";
import Header from "../components/header";
import API from "../services/API";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import LoadingBar from "react-top-loading-bar";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";


export default function AreaWiseReport() {
  const [areadata, setAreaData] = useState([]);
  const [totalpendingAmount, setTotalPending] = useState(0);
  const [totalupaidCustomer, setTotalUnpaid] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  let userJson;

  const user = localStorage.getItem("user") || sessionStorage.getItem("user");

  if (user) {
    userJson = JSON.parse(user);
  }

  const Areadata = () => {
    const AreaDataList = areadata.map((data) => {
      return (
        <div className="area-report-data-div">
          <div className="area-report-data">
            <p className="area-report-data-label">{data.AREA}</p>
          </div>
          <div className="area-report-data">
            <p className="area-report-data-label">{data.cust_count}</p>
          </div>
          <div className="area-report-data">
            <p className="area-report-data-label">{data.pending}</p>
          </div>
        </div>
      );
    });

    return AreaDataList;
  };

  // create a props object to pass the text and height of the header
  const headerprops = {
    text: "Area-wise Due Report",
    height: "10vh",
  };

  let agentAreaSummaryAPIParams = {
    agentId: userJson.agentId,
    operatorId: userJson.operatorId,
  };

  useEffect(() => {
    setProgress(30);
    API.agentAreaSummaryAPI(agentAreaSummaryAPIParams)
      .then((response) => {
        setProgress(60);
        setIsLoading(false);
        setAreaData(response.data.results);

        let totalpending = 0;
        let totalunpaid = 0;

        response.data.results.map((data) => {
          totalpending += parseInt(data.pending);
          totalunpaid += parseInt(data.cust_count);
        });

        setTotalPending(totalpending);
        setTotalUnpaid(totalunpaid);
        setProgress(100);
      })

      .catch((error) => {
        setProgress(100);

        console.log(error);
      });
  }, []);

  return (
    <>
      <Header name={t("Areawise_lbl_Header")} />
      <LoadingBar
        color="#f11946"
        height={4}
        shadow={true}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container-report-area">
        <div className="report-data-container">
          <div className="area-report-head-div">
            {/* three heading in single line */}

            <div className="area-report-head">
              <p className="area-report-head-label">{t("AreaWise_lbl_Area_Name")}</p>
            </div>
            <div className="area-report-head">
              <p className="area-report-head-label">{t("AreaWise_lbl_Customer_count")}</p>
            </div>
            <div className="area-report-head">
              <p className="area-report-head-label">{t("AreaWise_lbl_Pending")}</p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center"}}>
              {isLoading && (
                <Spinner
                  animation="border"
                  variant="info"
                  style={{ marginTop: "100px" }}
                />
              )}
          </div>
          <div className="report-data">
            <Areadata />
          </div>
        </div>

        <div className="area-float-div">
          <div className="report-total">
            <div className="total-amount-pending">
              <p className="total-amount-pending-label">
                {t("Areawise_lbl_total_pending")} :{" "}
              </p>
              <p className="total-amount-collected-value">
                ₹{totalpendingAmount}
              </p>
            </div>

            <div className="total-amount-collected">
              <p className="no-of-unpaid-label">
                {t("Aeawise_lbl_number_of_unpaid")} :{" "}
              </p>
              <p className="no-of-transactions-value">{totalupaidCustomer}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
