import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { TfiMobile } from "react-icons/tfi";
import Header from "../components/header";
import "../css/RegisterComplaints.css";
import "../css/STBHistory.css";
import { Select } from "@mui/material";
import { useEffect } from "react";
import API from "../services/API";
import ReactLoading from "react-loading";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function RegisterComplaints() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [customer, setCustomer] = useState(location.state);
  // const data = new URLSearchParams(location.search).get("data");
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  const [serviceType, setServiceType] = useState("Cable");
  const [complaintCategory, setComplaintCategory] = useState("");
  const [complaintDescription, setComplaintDescription] = useState("");
  const [alert, setalert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // function to generate random complaint id of length 8 and first 7 is random letters and last is random number
  const generateComplaintId = () => {
    let complaintId = "";
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    for (let i = 0; i < 7; i++) {
      complaintId += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    complaintId += numbers.charAt(Math.floor(Math.random() * numbers.length));
    return complaintId;
  };
  console.log(localStorage.getItem("user"));
  const Issues = {
    Cable: [
      "Cable Issue",
      "No Signal",
      "Box Not On",
      "Only Few Channel",
      "Channel Stuck",
      "Payment Issue",
      "Setup Box Issue",
      "Setup Box Repair",
      "Package Change Request",
      "Request Stop Service",
      "Request Start Service",
      "Request Pause Service",
      "Others",
    ],
    Internet: [
      "Bill request",
      "Wi-Fi router Issue",
      "Wi-Fi router red light",
      "Payment Issue",
      "Speed Isue",
      "Connectivity Issue",
      "Internet Shifting",
      "Plan change request",
      "Refer Friend 50% off",
      "Wi-Fi router replacement",
      "Others",
    ],
  };
  const handleComplaintTypeChange = (e) => {
    console.log("e.target.value");
    setServiceType(e.target.value);
    setComplaintCategory(""); // Reset the selected category when the type changes
  };

  const complaintCategoryOptions = Issues[serviceType].map((issue) => (
    <option key={issue} value={issue}>
      {issue}
    </option>
  ));

  const handleAddComplaint = () => {
    setIsLoading(true);
    const complaintDate = new Date().toLocaleDateString();
    console.log("Date", complaintDate);
    const complaintId = generateComplaintId();
    console.log("CompalintID", complaintId);
    console.log("CompalintID", user);
    const data = {
      complaintId: complaintId,
      customerId: customer.customerId,
      complaintType: serviceType,
      complaintDetails: complaintDescription,
      agentId: user.agentId,
      complaintDate: complaintDate,
      customerPhone: customer.phone,
      operatorId: user.operatorId,
    };

    API.addComplaint(data)
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        if (response.data.messageFlag === "2") {
          console.log("Duplicate Complaint");
          handleAddComplaint();
        }
        if (response.data.messageFlag === "S") {
          toast.success(t("registercomplaints_lbl_success"));
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(t("Registercomplaints_lbl_failure"));
      });
  };
  return (
    <div>
      <Header
        name={t("Registercomplaints_lbl_Header")}
        link={"/collectPayment"}
      />
      <div className="STBHistory-div-container">
        <div className="StaticDiv">
          <div className="customer-card-div-temp">
            <div
              className="card-div-history"
              onClick={() => navigate("/collectPayment")}
            >
              <div className="card-group1-div">
                <div class="card-line1-div">
                  <p className="card-name-p">
                    {t("Registercomplaints_lbl_Customer_name")} :{" "}
                    {customer ? customer.customerName : "NA"}
                  </p>
                </div>

                <div className="card-line2-div">
                  <p className="card-name-p">
                    {t("Registercomplaints_lbl_Customer_Id")} :{" "}
                    {customer ? customer.managedCustomerId : "NA"}
                  </p>
                </div>

                <div className="card-line3-div">
                  <div style={{ display: "flex" }}>
                    <TfiMobile className="card-mobileIcon" />
                    <p className="card-phone-p">
                      {customer ? customer.phone : "NA"}
                    </p>
                  </div>

                  <p
                    className="card-status-p"
                    style={{
                      backgroundColor:
                        customer.status === "Active"
                          ? "#a0c334"
                          : customer.status === "Suspended"
                          ? "#DC1515"
                          : "#000000",

                      width: "100px",
                    }}
                  >
                    {customer ? customer.status : "NA"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="register-complaint-container">
            <div className="register-complaint-form-row">
              <div className="register-complaint-form-label">
                {t("Registercomplaints_lbl_servicetype")}
              </div>
              <select
                className="register-complaint-form-input"
                value={serviceType}
                onChange={handleComplaintTypeChange}
              >
                <option value="Cable">Cable</option>
                <option value="Internet">Internet</option>.
              </select>
            </div>
            <div className="register-complaint-form-row">
              <div className="register-complaint-form-label">
                {t("Registercomplaints_lbl_complaintcategory")}
              </div>
              <select
                className="register-complaint-form-input"
                value={complaintCategory}
                onChange={(e) => setComplaintCategory(e.target.value)}
              >
                {complaintCategoryOptions}
              </select>
            </div>
            <div className="register-complaint-form-row">
              <input
                className="register-complaint-form-input"
                placeholder={t("Registercomplaints_lbl_descriptionplaceholder")}
                style={{ marginTop: "-2%" }}
                value={complaintDescription}
                onChange={(e) => setComplaintDescription(e.target.value)}
              />
            </div>
            <button
              className="register-complaint-form-btn"
              onClick={handleAddComplaint}
            >
              {t("Registercomplaints_lbl_registerbtn")}
            </button>
          </div>
        </div>
        <div className="DynamicDivLoading">
          {isLoading && (
            <ReactLoading
              type={"bars"}
              color={"#0090da"}
              height={75}
              width={75}
            />
          )}
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </div>
  );
}
