import MyDialog_prop from "../components/MyDialog_prop";
import React, { useState, useEffect } from "react";
import "../css/RecordVisit.css";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ReactLoading from "react-loading";

import API from "../services/API";
import { useTranslation } from "react-i18next";

export default function RecordVisit() {
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState("");
  const [remark, setRemark] = useState("");
  const [alert, setalert] = useState(false);
  const [apiResponseTitle, setAPIResponseTitle] = useState("");
  const [apiResponseContent, setAPIResponseContent] = useState("");
  const [CError, setCustError] = useState("");
  const [RError, setRemarkError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [DialogContentTitle, setDialogContentTitle] = useState("");
  const [DialogSubTitle, setDialogSubTitle] = useState("");
  const { t } = useTranslation();
  var dateTime = {
    hours: null,
    minutes: null,
    seconds: null,
    day: null,
    month: null,
    year: null,
  };

  let currentDate = new Date();

  const setDateTime = () => {
    dateTime.hours = currentDate.getHours();
    dateTime.minutes = currentDate.getMinutes();
    dateTime.seconds = currentDate.getSeconds();

    if (dateTime.hours < 10) {
      dateTime.hours = "0" + dateTime.hours;
    }

    if (dateTime.minutes < 10) {
      dateTime.minutes = "0" + dateTime.minutes;
    }

    dateTime.day = currentDate.getDate();
    dateTime.month = currentDate.getMonth() + 1;
    dateTime.year = currentDate.getFullYear();

    if (dateTime.day < 10) {
      dateTime.day = "0" + dateTime.day;
    }

    if (dateTime.month < 10) {
      dateTime.month = "0" + dateTime.month;
    }

    let temp =
      dateTime.year +
      "-" +
      dateTime.month +
      "-" +
      dateTime.day +
      " " +
      dateTime.hours +
      ":" +
      dateTime.minutes +
      ":" +
      dateTime.seconds;

    return temp;
  };

  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );

  const handleSubmit = () => {
    const customerData = {
      customerId: customerID,
      agentId: user.agentId,
      operatorId: user.operatorId,
      dateTime: setDateTime(),
      FLAG: "A",
      remarks: remark,
    };

    if (customerID === "" && remark === "") {
      setCustError("Please Enter Customer ID");
      setRemarkError("Please Enter Remarks");
      return;
    } else if (customerID === "") {
      setCustError("Please Enter Customer ID");
      return;
    } else if (remark === "") {
      setRemarkError("Please Enter Remarks");
      return;
    }

    setIsLoading(true);

    API.recordVisit(customerData)
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response.data.p_out_mssg === "Success") {
          setAPIResponseTitle(response.data.p_out_mssg);
          setAPIResponseContent("Remark Submitted Successfully");
          setalert(true);
          setDialogSubTitle("Information Updated Successfully");
          setDialogContentTitle("SUCCESS");
        } else {
          setAPIResponseTitle("Error");
          setAPIResponseContent(response.data.p_out_mssg);
          setDialogSubTitle(response.data.p_out_mssg);
          setDialogContentTitle("Error");
          setalert(true);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const dismiss_dialog = () => {
    setalert(false);
    /*toLink: "/customer/collectPayment/addChannel",
          state: customerDetail*/
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };
  return (
    <>
      <Header name={t("Recordvisit_lbl_Header")} link={"/collectPayment/"} />
      <div className="container-record-visit">
        <div className="record-visit-form">
          <div className="record-visit-form-row">
            <div className="record-visit-form-label">
              {t("Recordvisit_lbl_Customer_Id")}
            </div>
            <input
              className="record-visit-form-input"
              type="text"
              placeholder={t("Recordvisit_lbl_custid_placeholder")}
              value={customerID}
              onChange={(e) => {
                setCustomerID(e.target.value);
                setCustError("");
              }}
              style={{
                borderBottomColor: CError ? "red" : "#333333",
              }}
            />
            <p
              style={{
                color: "red",
                visibility: CError ? "visible" : "hidden",
              }}
            >
              {CError}
            </p>
          </div>
          <div className="record-visit-form-row">
            <div className="record-visit-form-label">
              {t("Recordvisit_lbl_Remarks")}
            </div>
            <input
              className="record-visit-form-input"
              type="text"
              placeholder={t("Recordvisit_lbl_remarks_placeholder")}
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
                setRemarkError("");
              }}
              style={{
                borderBottomColor: RError ? "red" : "#333333",
              }}
            />
            <p
              style={{
                color: "red",
                visibility: RError ? "visible" : "hidden",
              }}
            >
              {RError}
            </p>
          </div>
          <div className="agent-visit-record-data">
            <table className="agent-visit-record-table">
              <tr>
                <td className="agent-visit-label">
                  {t("Recordvisit_lbl_agentname")}
                </td>
                <td className="agent-visit-colon">:</td>
                <td className="agent-visit-value">{user.agentName}</td>
              </tr>
              <tr>
                <td className="agent-visit-label">
                  {t("Recordvisit_lbl_agentid")}
                </td>
                <td className="agent-visit-colon">:</td>
                <td className="agent-visit-value">{user.agentId}</td>
              </tr>
              {/* <tr>
                                <td className="agent-visit-label">
                                    Visited Time
                                </td>
                                <td className="agent-visit-colon">:</td>
                                <td className="agent-visit-value">{fullDateTime}</td>
                            </tr> */}
            </table>
          </div>
          <button className="record-visit-button" onClick={handleSubmit}>
            {t("Recordvisit_lbl_submitbtn")}
          </button>
          <button
            className="visited-history-button"
            onClick={() => navigate("historyVisit")}
          >
            {t("Recordvisit_lbl_visited_history")}
          </button>
          {/*  <Dialog
              open={alert}
              onClose={() => setalert(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ fontFamily: "Noto Sans" }}
              >
                {apiResponseTitle}
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Noto Sans",
                    color: "black",
                    border: "none",
                    margin: "10px",
                    padding: "5px",
                  }}
                >
                  {apiResponseContent}
                </DialogContentText>
              </DialogContent>
            </Dialog> */}
          <MyDialog_prop
            open={alert}
            onClose={dismiss_dialog}
            title={DialogContentTitle}
            contentText={DialogSubTitle}
          />{" "}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoading && (
            <ReactLoading
              type={"bars"}
              color={"#0090da"}
              height={75}
              width={75}
            />
          )}
        </div>
      </div>
    </>
  );
}
