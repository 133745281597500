import React, { useEffect } from "react";
import { RiSortDesc } from "react-icons/ri";
import Navbar from "../components/navbar";
import { useState, useMemo } from "react";
import Drawer from "react-bottom-drawer";
import { TfiMobile } from "react-icons/tfi";
import { useNavigate } from "react-router";
import API from "../services/API";
import { Spinner } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import ContentLoader, { Facebook } from "react-content-loader";
import FloatingButton from "../components/FloatingButton";
import "../css/Customer.css";
import "../css/global.css";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";

export default function Customer() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [dropDownAreaData, setDropDownAreaData] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [filtercustomerData, setfiltercustomerData] = useState([]);
  const { t } = useTranslation();
  const [sortOptions, setSortOptions] = useState("All");
  const [sortBy, setSortBy] = useState("CustomerId");
  const [sortType, setSortType] = useState("ASC");
  const [search, setSearch] = useState("");
  const [area, setArea] = useState("All");
  const [isLoading, setisLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );

  const fetchCustomerData = () => {
    //setProgress(20);
    setisLoading(true);
    setfiltercustomerData([]);
    setcustomerData([]);

    const homedata = localStorage.getItem('homedata');
    const LocalData = JSON.parse(homedata);
    const Agent_ID = LocalData.agent_id;

    let data = {
      agentId: Agent_ID
    }

    //console.log("Request Body:", data);

    API.allCustomerData(data)
      .then((response) => {
        // setProgress(40);
        //console.log("response",response);
        //console.log("response.data.results",response.data.results);
        setcustomerData(response.data.results);
        // setProgress(60);
        setfiltercustomerData(response.data.results);
        //setProgress(80);
        handleFilterSubmit();
        //setProgress(100);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Inside catch, Error:",error);
        //setProgress(100);
        setisLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomerData();
    API.dropdownAgentDataAPI({ operatorId: user.operatorId })
      .then((response) => {
        setDropDownAreaData(response.data.all_areas);
      })

      .catch((error) => {
        console.log(error);
      });
  }, []);

  const DropDownArea = () => {
    const DropDownAreaData = dropDownAreaData.map((data) => {
      return <option value={data.id}>{data.area_name}</option>;
    });
    return DropDownAreaData;
  };

  useEffect(() => {
    if (area === "All") {
      setfiltercustomerData([...customerData]); // Reset filter data to all customers
    } else {
      const results = customerData.filter(
        (customer) => customer.AREA_ID === area
      );
      setfiltercustomerData(results);
    }
  }, [area, customerData]);

  useEffect(() => {
    if (area === "All") {
      setfiltercustomerData(customerData);
    } else {
      console.log("Selected Area: ", area);
      // based the seleceted area filter the data
      const results = customerData.filter(
        (customer) => customer.AREA_ID === parseInt(area)
      );
      console.log(results);
      setfiltercustomerData(results);
    }
  }, [area]);

  useEffect(() => {
    const results = customerData.filter(
      (customer) =>
        customer.customerName?.toLowerCase().includes(search?.toLowerCase()) ||
        customer.phone?.toLowerCase().includes(search?.toLowerCase()) ||
        customer.managedCustomerId
          ?.toLowerCase()
          .includes(search?.toLowerCase()) ||
        customer.endDate?.toLowerCase().includes(search?.toLowerCase()) ||
        customer.status?.toLowerCase().includes(search?.toLowerCase()) ||
        customer.totalPayableAmount?.toString().includes(search?.toLowerCase())
    );
    setfiltercustomerData(results);
  }, [search]);

  const Customers = () => {
    const eachCustomer = filtercustomerData.map((customer) => {
      return (
        <div
          className="card-div"
          onClick={() =>
            navigate("/customer/collectPayment", {
              state: customer,
            })
          }
        >
          <div className="card-group1-div">
            <div class="card-line1-div">
              <p className="card-name-p">{customer.customerName}</p>
              <p
                className="card-price-p"
                style={{
                  color:
                    customer.totalPayableAmount > 0 ? "#DC1515" : "#a0c334",
                }}
              >
                ₹ {customer.totalPayableAmount}
              </p>
            </div>

            <div className="card-line2-div">
              <p className="card-date-p">{customer.endDate}</p>
              <p className="card-reg-p">{customer.managedCustomerId}</p>
            </div>

            <div className="card-line3-div">
              <div style={{ display: "flex" }}>
                <TfiMobile className="card-mobileIcon" />
                <p className="card-phone-p">{customer.phone}</p>
              </div>

              <p
                className="card-status-p"
                style={{
                  backgroundColor:
                    customer.status === "Active" || customer.status === "ACTIVE"
                      ? "#a0c334"
                      : customer.status === "Suspended"
                      ? "#DC1515"
                      : "#0090da",
                }}
              >
                {customer.status}
              </p>
            </div>
          </div>
          {customer.displayField ? (
            <div className="card-group2-div">
              <div className="card-underline-div"></div>
              <p className="card-address-p">{customer.displayField}</p>
            </div>
          ) : null}
        </div>
      );
    });
    return <>{eachCustomer}</>;
  };

  const onClose = React.useCallback(() => {
    setIsVisible(false);
  }, []);

  const openDrawer = React.useCallback(() => setIsVisible(true), []);
  const ChangeDataBasedOnFilter = () => {
    const data = customerData;

    if (sortOptions === "Paid") {
      const ActiveCustomers = data.filter(
        (customer) => customer.status === "Active"
      );
      const PaidCustomers = ActiveCustomers.filter(
        (customer) => customer.totalPayableAmount <= 0
      );
      setfiltercustomerData(PaidCustomers);
    } else if (sortOptions === "Unpaid") {
      const ActiveCustomers = data.filter(
        (customer) => customer.status === "Active"
      );
      const UnpaidCustomers = ActiveCustomers.filter(
        (customer) => customer.totalPayableAmount > 0
      );
      setfiltercustomerData(UnpaidCustomers);
    } else {
      setfiltercustomerData(data);
    }

    if (sortBy === "CustomerId") {
      setfiltercustomerData((prevState) =>
        [...prevState].sort((a, b) =>
          a.managedCustomerId.localeCompare(b.managedCustomerId, "en", {
            numeric: true,
            ignorePunctuation: true,
            sensitivity: "base",
          })
        )
      );
    } else if (sortBy === "Pending") {
      setfiltercustomerData((prevState) =>
        [...prevState].sort(
          (a, b) => a.totalPayableAmount - b.totalPayableAmount
        )
      );
    }

    if (sortType === "ASC") {
      setfiltercustomerData((prevState) => [...prevState].reverse());
    }
  };

  // useEffect(() => {
  //   console.log(filtercustomerData);
  // }, [filtercustomerData]);

  const handleFilterSubmit = (e) => {
    // e.preventDefault();
    setIsVisible(false);
    ChangeDataBasedOnFilter();
  };

  return (
    <>
      <div className="customer-container" style={{ backgroundColor: "#e8ebeb"}}>
        <div className="header-blue">
          <div className="area-div">
            {/* <p className="area-p">{t("Customers_area_p")}</p> */}
            <input
              type={"text"}
              placeholder={t("Customers_search_placeholder")}
              onChange={(e) => setSearch(e.target.value)}
              className="area-dropdown"
              style={{
                position: "absolute",
                top: "90%",
                left: "3px",
                width: "50vw"
              }}
            />
            <select
              name="test"
              className="area-dropdown"
              placeholder="All Areas"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              style={{width: "50vw",left: "-8px"}}
            >
              <option value="All">All Areas</option>
              <DropDownArea />
            </select>
          </div>

          <div className="filter-div">
            <p className="filter-p">{t("Customers_filter")}</p>
            <div className="filter-button" onClick={() => openDrawer()}>
              <RiSortDesc className="filter-icon" />
            </div>
          </div>
        </div>

        <div className="customer-page-card-div">
          <FloatingButton />
          {isLoading && (
           <>
              {/* <div className="card-div" style={{ border: "none", width: "90%" }}>
                <ContentLoader width={"100%"} style={{ marginTop: "20px" }}>
                  <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                  <ContentLoader width={"100%"} style={{ marginTop: "20px" }}>
                    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                  </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                  <ContentLoader width={"100%"} style={{ marginTop: "20px" }}>
                    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                  </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                  <ContentLoader width={"100%"} style={{ marginTop: "20px" }}>
                    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                  </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                  <ContentLoader width={"100%"} style={{ marginTop: "20px" }}>
                    <rect x="0" y="0" rx="5" ry="5" width="500" height="200" />
                  </ContentLoader>
              </div> */}
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                <ContentLoader
                  speed={2}
                  width={400}
                  height={160}
                  viewBox="0 0 400 160"
                  backgroundColor="white"
                  foregroundColor="#333333"
                >
                  <rect x="80" y="20" rx="3" ry="3" width="220" height="10" />
                  <rect x="80" y="40" rx="3" ry="3" width="180" height="10" />
                  <rect x="80" y="60" rx="3" ry="3" width="130" height="10" />
                </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                <ContentLoader
                  speed={2}
                  width={400}
                  height={160}
                  viewBox="0 0 400 160"
                  backgroundColor="white"
                  foregroundColor="#333333"
                >
                  <rect x="80" y="20" rx="3" ry="3" width="220" height="10" />
                  <rect x="80" y="40" rx="3" ry="3" width="180" height="10" />
                  <rect x="80" y="60" rx="3" ry="3" width="130" height="10" />
                  {/* <circle cx="40" cy="40" r="30" /> */}
                </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                <ContentLoader
                  speed={2}
                  width={400}
                  height={160}
                  viewBox="0 0 400 160"
                  backgroundColor="white"
                  foregroundColor="#333333"
                >
                  <rect x="80" y="20" rx="3" ry="3" width="220" height="10" />
                  <rect x="80" y="40" rx="3" ry="3" width="180" height="10" />
                  <rect x="80" y="60" rx="3" ry="3" width="130" height="10" />
                  {/* <circle cx="40" cy="40" r="30" /> */}
                </ContentLoader>
              </div>
              <div className="card-div" style={{ border: "none", width: "90%" }}>
                <ContentLoader
                  speed={2}
                  width={400}
                  height={160}
                  viewBox="0 0 400 160"
                  backgroundColor="white"
                  foregroundColor="#333333"
                >
                  <rect x="80" y="20" rx="3" ry="3" width="220" height="10" />
                  <rect x="80" y="40" rx="3" ry="3" width="180" height="10" />
                  <rect x="80" y="60" rx="3" ry="3" width="130" height="10" />
                  {/* <circle cx="40" cy="40" r="30" /> */}
                </ContentLoader>
              </div>
            </>
          )}
          <Customers />
        </div>

        <LoadingBar
          color="#f11946"
          height={4}
          shadow={true}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <Drawer
          isVisible={isVisible}
          onClose={onClose}
          className={"drawer"}
          hideScrollbars={"true"}
        >
          <div style={{ padding: "10px" }}>
            <p className="DrawerTitle">{t("Customers_filters_sort_options")}</p>
            <div sty>
              <div className="SortOptionsContent">
                <p className="SortOptionsLabel">{t("Customers_filters_sort_options_paid")}</p>
                <input
                  type={"radio"}
                  name={"sort-option"}
                  className="DrawerRadioButtons"
                  value="Paid"
                  checked={sortOptions === "Paid"}
                  onChange={(e) => setSortOptions(e.target.value)}
                />
              </div>
              <div className="SortOptionsContent">
                <p className="SortOptionsLabel">{t("Customers_filters_sort_options_unpaid")}</p>
                <input
                  type={"radio"}
                  name={"sort-option"}
                  className="DrawerRadioButtons"
                  value="Unpaid"
                  checked={sortOptions === "Unpaid"}
                  onChange={(e) => setSortOptions(e.target.value)}
                />
              </div>
              <div className="SortOptionsContent">
                <p className="SortOptionsLabel">{t("Customers_filters_sort_options_all")}</p>
                <input
                  type={"radio"}
                  name={"sort-option"}
                  value="All"
                  className="DrawerRadioButtons"
                  checked={sortOptions === "All"}
                  onChange={(e) => setSortOptions(e.target.value)}
                />
              </div>
            </div>
            <p className="DrawerTitle">{t("Customers_filters_sort_by")}</p>
            <div className="DrawerSortColumn">
              <div>
                <input
                  type={"radio"}
                  name={"sort-by"}
                  value={"Pending"}
                  className="DrawerRadioButtons"
                  checked={sortBy === "Pending"}
                  onChange={(e) => setSortBy(e.target.value)}
                />
                <label className="DrawerRadioLabel">{t("Customers_filters_sort_by_pendingamt")}</label>
              </div>
              <div>
                <input
                  type={"radio"}
                  name={"sort-by"}
                  value={"CustomerId"}
                  id={"English"}
                  className="DrawerRadioButtons"
                  checked={sortBy === "CustomerId"}
                  onChange={(e) => setSortBy(e.target.value)}
                />
                <label className="DrawerRadioLabel">{t("Customers_filters_sort_by_customerid")}</label>
              </div>
            </div>
            <p className="DrawerTitle" style={{ marginTop: "20px" }}>
              {t("Customers_filters_sort_type")}
            </p>
            <div className="DrawerSortColumn">
              <div>
                <input
                  type={"radio"}
                  name={"sort-type"}
                  value={"ASC"}
                  id={"English"}
                  className="DrawerRadioButtons"
                  checked={sortType === "ASC"}
                  onChange={(e) => setSortType(e.target.value)}
                />
                <label className="DrawerRadioLabel">{t("Customers_filters_sort_type_asc")}</label>
              </div>
              <div>
                <input
                  type={"radio"}
                  name={"sort-type"}
                  value={"DESC"}
                  id={"English"}
                  className="DrawerRadioButtons"
                  checked={sortType === "DESC"}
                  onChange={(e) => setSortType(e.target.value)}
                />
                <label className="DrawerRadioLabel">
                  {t("Customers_filters_sort_type_desc")}
                </label>
              </div>
            </div>
            <button className="DrawerSubmitButton" onClick={handleFilterSubmit}>
              <span>
                {t("Customers_filters_submitbtn")}
              </span>
            </button>
          </div>
        </Drawer>
      </div>
      <Navbar value={1} />
    </>
  );
}
