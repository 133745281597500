import React from "react";
import Navbar from "../components/navbar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Header from "../components/header";

import "../css/MoreOptions.css";
import "../css/global.css";
import { useTranslation } from "react-i18next";

function MoreOptions() {
  const navigate = useNavigate();
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  const MSO_ID = user.MSO_ID;
  const { t } = useTranslation();
  let options = [
    {
      id: 1,
      name: t("Moreoptions_lbl_Daily_Report"),
      imgUrl: "daily_report.png",
      toLink: "/dailyReport",
      toShow: true,
    },
    {
      id: 2,
      name: t("Moreoptions_lbl_Monthly_Report"),
      imgUrl: "monthly_report.png",
      toLink: "/monthlyReport",
      toShow: true,
    },
    {
      id: 3,
      name: t("Moreoptions_lbl_expiry_report"),
      imgUrl: "Expiry_report.png",
      toLink: "/subExpiryReport",
      toShow: true,
    },
    {
      id: 4,
      name: t("Moreoptions_lbl_View_Complaints"),
      imgUrl: "complaints.png",
      toLink: "/complaints",
      toShow: true,
    },
    {
      id: 5,
      name: t("Moreoptions_lbl_Customer_Summary"),
      imgUrl: "cust_summary.svg",
      toLink: "/customerStatistics",
      toShow: true,
    },
    {
      id: 6,
      name: t("Moreoptions_lbl_Area_Wise_Due_Report"),
      imgUrl: "Area_wise.png",
      toLink: "/areaWiseReport",
      toShow: true,
    },
    {
      id: 7,
      name: t("Moreoptions_lbl_Renewal_Report"),
      imgUrl: "renewal_report.png",
      toLink: "",
      toShow: MSO_ID === 0 ? false : true,
    },
    {
      id: 8,
      name: t("Moreoptions_lbl_Wallet_Recharge"),
      imgUrl: "wallet_recharge.png",
      toLink: "",
      toShow: MSO_ID === 0 ? false : true,
    },
  ];

  const headerprops = {
    text: "More Options",
    height: "10vh",
  };

  useEffect(() => {
    // window.history.pushState({}, "");
    // window.addEventListener("popstate", function (e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     window.history.pushState({}, "");
    // });
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f1f1f1",height: "100vh"}}>
        <Header name={t("Moreoptions_lbl_Header")} />
        <div className="OptionsContainer">
          {options.map((option) => {
            if (!option.toShow) return null;
            return (
              <div
                className="EachOption"
                id={option.id}
                onClick={() => navigate(option.toLink)}
              >
                <img
                  src={require("../assets/" + option.imgUrl)}
                  alt={option.name}
                  width={"50%"}
                />
                <p>{option.name}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Navbar value={2} />
    </>
  );
}

export default MoreOptions;
