import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Header from "../components/header";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import API from "../services/API";

import "../css/SubReport.css";
import "../css/global.css";
import LoadingBar from "react-top-loading-bar";
import { useTranslation } from "react-i18next";

export default function SubscriptionExpiryReport() {
  // const [showItems, setshowItems] = useState([]);
  // const [dateResponse, setdateResponse] = useState(null);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [expiryResponse, setexpiryResponse] = useState(null);
  const [alert, setalert] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // const [isDateLoading, setisDateLoading] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );

  let agentData = {
    agent_id: user.agentId,
    operator_id: user.operatorId,
  };

  const dialer = () => {
    window.location.href = "tel:" + phone;
  };

  useEffect(() => {
    setisLoading(true);
    setProgress(30)
    API.subscriptionExpiryReportAPI(agentData)

      .then((response) => {
        setProgress(60)
        setexpiryResponse(response.data);
        setisLoading(false);
        setProgress(100)
      })

      .catch((e) => {
        setisLoading(false);
        console.log(e);
        setProgress(100)
      });
  }, []);

  const ExpiryCount = ({ date }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [countData, setCountData] = useState(null);

    useEffect(() => {
      let isMounted = true;
      setIsLoading(true);

      // Change the format of date to YYYY-MM-DD
      let dateArray = date.split("-");
      let newDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];

      let expiryData = {
        agent_id: user.agentId,
        operator_id: user.operatorId,
        pre_end_date: newDate,
      };

      API.subscriptionExpiryReportCountAPI(expiryData)
        .then((response) => {
          if (isMounted) {
            setCountData(response.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });

      return () => {
        isMounted = false;
      };
    }, [date]);

    return (
      <table className="expiryCountTable">
        <tr className="borderExpiryHead">
          <th>{t("Subscriptionexpiry_lbl_custID")}</th>
          <th>
            {t("Subscriptionexpiry_lbl_custName")}
          </th>
          <th>
            {t("Subscriptionexpiry_lbl_custPhone")}
          </th>
        </tr>
        {isLoading && (
          <div className="smallLoader">
            <Spinner animation="border" variant="info" />
          </div>
        )}
        {countData &&
          countData.c_report.map((report) => {
            return (
              <tr>
                <td>{report.CUSTOMER_ID}</td>
                <td style={{ fontWeight: 700 }}>{report.NAME}</td>
                <td
                  style={{
                    color: "#0090DA",
                    textDecorationLine: "underline",
                    fontWeight: 700,
                  }}
                  onClick={() => {
                    setPhone(report.PHONE);
                    setalert(true);
                  }}
                >
                  {report.PHONE}
                </td>
              </tr>
            );
          })}
      </table>
    );
  };

  const subExpiryList = () => {
    const handleItemClick = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    const SubExpiryDataList = expiryResponse.stb_report.map((data, index) => {
      return (
        <React.Fragment key={index}>
          <div className="expiry-report-data-div">
            <div className="expiry-report-data">
              <p className="expiry-report-data-label">{data.DAYS}</p>
            </div>
            <div className="expiry-report-data">
              <p className="expiry-report-data-label">{data.PRE_END_DATE}</p>
            </div>
            <div className="expiry-report-data">
              <p
                className="expiry-report-data-label"
                style={{
                  width: "10px",
                  color: "#0090DA",
                  borderBottom: "2px solid #0090DA",
                  paddingBottom: "2px",
                  fontWeight: 700,
                }}
                onClick={() => handleItemClick(index)}
              >
                {data.COUNT}
              </p>
            </div>
          </div>
          {openIndex === index && <ExpiryCount date={data.PRE_END_DATE} />}
        </React.Fragment>
      );
    });

    return SubExpiryDataList;
  };

  return (
    <>
      <Header name={t("Subscriptionexpiry_lbl_Header")} />
      <LoadingBar
        color="#f11946"
        height={4}
        shadow={true}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="container-expiry-report">
        <div className="expiry-data-container">
          <div className="expiry-report-head-div">
            <div className="expiry-report-head">
              <p className="expiry-report-head-label">
                {t("Subscriptionexpiry_lbl_Daysaway")}
              </p>
            </div>
            <div className="expiry-report-head">
              <p className="expiry-report-head-label">
                {t("Subscriptionexpiry_lbl_Expired")}
              </p>
            </div>
            <div className="expiry-report-head">
              <p className="expiry-report-head-label">
                {t("Subscriptionexpiry_lbl_count")}
              </p>
            </div>
          </div>
        </div>

        <div className="expiry-data">
          {/* {isLoading && (
            <div>
              <Spinner
                animation="border"
                variant="info"
                style={{ marginTop: "50px" }}
              />
            </div>
          )} */}
          {expiryResponse && subExpiryList()}
        </div>
        <div style={{ display: "none" }} className="float-div-expiry">
          <button className="float-btn">
            {t("Subscriptionexpiry_lbl_print")}
          </button>
        </div>

        <Dialog
          open={alert}
          onClose={() => setalert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontFamily: "Noto Sans" }}
          >
            {t("Subscriptionexpiry_dialog_header")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                background: "var(--primay-app-color)",
                color: "white",
                border: "none",
                borderRadius: "20px",
                margin: "15px",
                padding: "10px",
              }}
            >
              {t("Subscriptionexpiry_dialog_renew")}
            </DialogContentText>

            <DialogContentText
              id="alert-dialog-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Noto Sans",
                color: "black",
                border: "solid black 1px",
                borderRadius: "20px",
                margin: "15px",
                padding: "10px",
              }}
              onClick={dialer}
            >
              {t("Subscriptionexpiry_dialog_callcustomer")}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}
