import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { TbPlus } from "react-icons/tb";
import { AiOutlineMinus } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import API from "../services/API";
import useGeoLocation from "../components/useGeoLocation";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import { FaAngleLeft } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import "../css/CustomerInfo.css";
import "../css/global.css";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-date-picker";

export default function CollectPayment() {
  const navigate = useNavigate();
  const customer = useLocation();
  const { t } = useTranslation();
  const location = useGeoLocation();
  const [stbOpen, setstbOpen] = useState(false);
  const [cusOpen, setcusOpen] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [alert, setalert] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const customerID = customer.state;
  const [isLoading, setIsLoading] = useState(false);
  const [collect, setCollect] = useState(false);
  const [paymentlink, setpaymentlink] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [amountpayeditable, setamountpayeditable] = useState(null);
  const [payment_comment, setpayment_comment] = useState(null);
  const [selectedValue, setSelectedValue] = useState("1");
  const [totalPay, settotalAmount] = useState("");
  const [paymentresponse, setpaymentresponse] = useState(null);
  const [paymentMobileNumber, setPaymentMobileNumber] = useState(null);
  const [isPhoneReadOnly, setPhoneReadOnly] = useState(true);
  const [isAmountReadOnly, setAmountReadOnly] = useState(false);
  const [Complaints, setComplaints] = useState([]);
  const [selectedComplaint, setSelectedComplaint] = useState([]);
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
//   useEffect(() => {
//     if (!customerID) {
//       navigate("/customer");
//     }
//   }, []);

  useEffect(() => {
    setIsLoading(true);
    const data = {
      // customerId: customerID ? customerID.customerId : "", 
      customerId: 1001752535,
      operatorId: user.operatorId,
      agentId: user.agentId,
    };

    API.getCustomerInfo(data)
      .then((response) => {
        console.log(response);
        setPaymentMobileNumber(response.data.customerDetailsList[0].phone);
        setCustomerDetails(response.data.customerDetailsList[0]);
        settotalAmount(response.data.customerDetailsList[0].totalPayableAmount);
        const tpa = parseFloat(
          response.data.customerDetailsList[0].totalPayableAmount
        );
        if (tpa > 0) {
          setamountpayeditable(tpa);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        if (user.techFlag === "N") {
          setPhoneReadOnly(false);
        } else {
          setPhoneReadOnly(true);
        }
        if (user.Amount_lock === "Y") {
          setAmountReadOnly(false);
        } else {
          setAmountReadOnly(true);
        }
      })

      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });

      fetchComplaints();
  }, []);

  const fetchComplaints = () => {
    API.viewCompalintAPI({ agent_id: user.agentId })
      .then((response) => {
        setComplaints(response.data.complaints);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  if (
    customerDetails !== undefined ||
    customerDetails !== null ||
    customerDetails.length !== 0
  ) {
    localStorage.setItem("stb", customerDetails.stbNumber);
    localStorage.setItem("vc", customerDetails.vcNumber);
    localStorage.setItem("customerDetails", JSON.stringify(customerDetails));
    // console.log(customerDetails)
  }

  const options = [
    {
      id: 1,
      name: "Update Status",
      imgUrl: "edit_customer.png",
      toLink: "",
      toShow: true,
    },
    {
      id: 2,
      name: t("Collectpayment_cust_update_location"),
      imgUrl: "update_location.png",
      toLink: "",
      toShow: true,
    },
    {
      id: 3,
      name: t("Collectpayment_cust_locate_address"),
      imgUrl: "location_collect.png",
      toLink: "",
      toShow: true,
    },
  ];

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const dismissCollectAlert = () => {
    setCollect(false);
    window.location.reload();
  };

  function dateUpdate(date) {
    setExpiryDate(date);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const collectData = {
    custommerName: customerDetails.customerName,
    customerId: customerDetails.customerId,
    stbNumber: customerDetails.stbNumber,
    expiryDate: moment(customerDetails.endDate).format("DD MMMM YYYY"),
    lastPaidAmount: customerDetails.amountCollected,
    baseAmount: customerDetails.baseBillAmount,
    taxAmount: customerDetails.taxBillAmount,
    totalPackAmount:
      customerDetails.baseBillAmount + customerDetails.taxBillAmount,
    previousBalance: customerDetails.previousPendingAmount,
    amountAdjusted: customerDetails.permanent_bal,
    totalPayableAmount: customerDetails.totalPayableAmount,
    nextExpiryDate: expiryDate,
  };

  return (
    <>
      <div className="blue-header">
        <div className="top-header">
            <div style={{ height: "2vh" }}>
            <FaAngleLeft
                onClick={() => {
                navigate(-1);
                }}
                style={{
                color: "white",
                height: "3vh",
                position: "absolute",
                left: "5vw",
                }}
            />
            </div>
        </div>
        <div style={{ height: "2vh", position: "absolute", left: "20vw" }}>
                <h2 className="upper-header-label">Customer Information</h2>
        </div>
      </div>
      <LoadingOverlay active={isLoading} spinner text="Please Wait...">
        <div className="PaymentContainer">
          <div className="tableContainer">
            <table className="TableHeader">
                <tr className="t-row-1">
                <td className="t-col-1">{t("Collectpayment_customer_name")}</td>
                <td className="t-col-2">:</td>
                <td className="owner-name-data">
                    {customerDetails ? customerDetails.customerName : "--"}
                </td>
                </tr>
                <tr className="t-row-2">
                <td className="t-col-1">{t("Collectpayment_customer_id")}</td>
                <td className="t-col-2">:</td>
                <td className="t-col-3">
                    {customerDetails ? customerDetails.customerId : "--"}
                </td>
                </tr>
                <tr className="t-row-3">
                <td className="t-col-1">{t("Phone")}</td>
                <td className="t-col-2">:</td>
                <td className="stb_fields">
                    {customerDetails ? customerDetails.phone : "--"}
                </td>
                </tr>
                <tr className="t-row-4">
                <td className="t-col-1">{t("Address")}</td>
                <td className="t-col-2">:</td>
                <td className="stb_fields">
                    {customerDetails ? customerDetails.address : "--"}
                </td>
                </tr>
            </table>
          </div>
          <div className="tableContainer">
            <table className="bodyTable1">
              <tr>
                <td className="body-col-1 lStyle">{t("Complaint Id")}</td>
                <td className="body-col-2 lStyle">:</td>
                <td className="body-col-3 lStyle">
                   {selectedComplaint.COMP_ID}
                </td>
              </tr>
              <tr>
                <td className="body-col-1 lStyle">{t("Complaint Date")}</td>
                <td className="body-col-2 lStyle">:</td>
                <td className="body-col-3 lStyle">
                   {formatDate(selectedComplaint.COMP_DATE)}
                </td>
              </tr>
              <tr>
                <td className="body-col-1 lStyle">{t("Complaint Type")}</td>
                <td className="body-col-2 lStyle">:</td>
                <td className="body-col-3 lStyle">
                   {selectedComplaint.CMP_TYPE}
                </td>
              </tr>
              <tr>
                <td className="body-col-1 lStyle">{t("Complaint Desc")}</td>
                <td className="body-col-2 lStyle">:</td>
                <td className="body-col-3 lStyle">
                   {selectedComplaint.CMP_DETAIL}
                </td>
              </tr>
              <tr>
                <td className="body-col-1 lStyle">{t("Current Status")}</td>
                <td className="body-col-2 lStyle">:</td>
                <td className="complaintStatus">
                   {Complaints.COMP_STATUS}
                </td>
              </tr>
            </table>
          </div>
          <div className="multipleOptionBox">
            {options.map((option) => {
              if (!option.toShow) return null;
              return (
                <div
                  className="EachOption"
                  id={option.id}
                  onClick={() => navigate(option.toLink)}
                >
                  <img
                    src={require("../assets/" + option.imgUrl)}
                    alt={option.name}
                    width={"50%"}
                  />
                  <p>{option.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
