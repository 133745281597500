import React, { useEffect, useState } from "react";
import Header from "../components/header";
import "../css/EditSetTopBox.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import moment from "moment";
import API from "../services/API";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

export default function EditSingleBox() {
  const navigate = useNavigate();
  const location = useLocation();
  const [customer, setCustomer] = useState(
    JSON.parse(localStorage.getItem("customerDetails"))
  );
  const gotNavigatestate = location.state;
  const { t } = useTranslation();
  const [stb, setStb] = useState("");
  const [stb_id, setstb_id] = useState("");
  const [vc, setVc] = useState("");
  const [endDate, setEndDate] = useState("");
  const [balance, setBalance] = useState(customer.totalPayableAmount || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [sOpen, setSOpen] = useState(false);
  const [fOpen, setFOpen] = useState(false);
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );

  console.log(customer);
  useEffect(() => {
    if (customer) {
      localStorage.setItem("customer", JSON.stringify(customer));
      setVc(customer.vcNumber);
      setStb(customer.stbNumber);
      setStatus(customer.status);
    }
  }, [customer]);

  const handleEditSTBSubmit = () => {
    setIsLoading(true)
    if (stb !== "" && vc !== "" && balance !== "") {
      console.log(
        JSON.stringify({
          agent_id: user.agentId,
          customer_num: customer.customerId,
          operator_id: user.operatorId,
          vc_number: vc,
          stb_number: stb,
          status: status,
          balance: balance,
          stb_id: customer.stb_id,
          flag: "S",
        })
      );
      API.editSTBInfo({
        agent_id: user.agentId,
        customer_num: customer.customerId,
        operator_id: user.operatorId,
        vc_number: vc,
        stb_number: stb,
        status: status,
        balance: balance,
        stb_id: stb_id,
        flag: "S",
      })
        .then((res) => {
          console.log(res);
          if (res.data.p_out_mssg_flg === "S") {
            setIsLoading(false)
            setSOpen(true)
            // setTimeout(() => {
            //   navigate(-1);
            // }, 5000);
          } else {
            console.log("STB Update Failed");
            setIsLoading(true)
            setFOpen(true)
          }
        })
        .catch((error) => {
          console.log(error);
          setFOpen(true)
          setIsLoading(true)
        });
    } else {
      //stb !== "" && vc !== "" && endDate !== "" && balance !== ""
      console.log("Error While Updating");
      console.log(stb);
      console.log(vc);
      console.log(endDate);
      console.log(balance);
      setIsLoading(false)
      setFOpen(true)
    }
  };

  const headerprops = {
    text: "Edit SetTop Box",
    height: "10vh",
  };

  return (
    <>
      <Header name={t("Editsettopbox_lbl_Header")} />
      <LoadingOverlay active={isLoading} spinner text="Updating...">
      <div className="edit-settopbox-container">
        <div className="edit-settopbox-form">
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_stb_no")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the STB no"
                value={stb}
                onChange={(e) => {
                  setStb(e.target.value);
                }}
              />
            </div>
            <img
              onClick={() =>
                navigate(
                  "/customer/collectPayment/editSetTopBox/barCodeScanner?field=stb"
                )
              }
              className="edit-settopbox-form-input-icon"
              src={require("../assets/barcodeicon.png")}
              style={{ width: "70px", height: "40px" }}
              alt="barcode"
            />
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_vc_no")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the VC no"
                value={vc}
                onChange={(e) => {
                  setVc(e.target.value);
                }}
              />
            </div>
            <img
              onClick={() =>
                navigate(
                  "/customer/collectPayment/editSetTopBox/barCodeScanner?field=vc"
                )
              }
              className="edit-settopbox-form-input-icon"
              src={require("../assets/barcodeicon.png")}
              style={{ width: "70px", height: "40px" }}
              alt="barcode"
            />
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_enddate")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                value={customer.endDate}
                readOnly
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_balance")}
              </div>
              <input
                className="edit-settopbox-form-input"
                type="text"
                placeholder="Enter the Balance"
                value={balance}
                readOnly
              />
            </div>
          </div>
          <div className="edit-settopbox-form-row">
            <div className="three-settopbox">
              <div className="edit-settopbox-form-label">
                {t("Editsettopbox_lbl_status")}
              </div>
              <select
                className="edit-settopbox-form-input"
                name="status"
                id="status"
                value={status}
                defaultValue={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={status} selected>
                  {status==="Active"?t("Editsettopbox_drop_active"):
                   status==="Suspended"?t("Editsettopbox_drop_Temporary"):
                   status==="Cancelled"?t("Editsettopbox_drop_Permanent"):""
                  }
                </option>
                {status==="Active"?"":
                  <option value="Active">
                    {t("Editsettopbox_drop_active")}
                  </option>
                }
                {status==="Suspended"?"":
                  <option value="Suspended">
                    {t("Editsettopbox_drop_Temporary")}
                  </option>
                }
                {status==="Cancelled"?"":
                  <option value="Cancelled">
                    {t("Editsettopbox_drop_Permanent")}
                  </option>
                }
              </select>
            </div>
          </div>
          <button
            className="edit-settopbox-form-button"
            onClick={handleEditSTBSubmit}
          >
            UPDATE
          </button>
          <Dialog
                open={sOpen}
                onClose={() => setSOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ borderRadius: "25px" }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333333",
                    paddingBottom: "10px"
                  }}
                >
                  Successful
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{
                    textAlign: "center"
                  }}>
                    STB Details Updated successfully.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Noto Sans",
                      background: "var(--primay-app-color)",
                      color: "white",
                      border: "none",
                      borderRadius: "20px",
                      margin: "15px",
                      padding: "10px",
                    }}
                    onClick={ () => { navigate(-1); } }
                  >
                    OK
                  </DialogContentText>
                </DialogContent>
          </Dialog>
          <Dialog
                open={fOpen}
                onClose={() => setFOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ borderRadius: "25px" }}
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    fontFamily: "Noto Sans",
                    fontStyle: "normal",
                    fontWeight: "600",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "#333333",
                    paddingBottom: "10px"
                  }}
                >
                  Failed!!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{
                    textAlign: "center"
                  }}>
                    STB Details Update Operation Failed. Please try again.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Noto Sans",
                      background: "var(--primay-app-color)",
                      color: "white",
                      border: "none",
                      borderRadius: "20px",
                      margin: "15px",
                      padding: "10px",
                    }}
                    onClick={ () => { setFOpen(false) } }
                  >
                    OK
                  </DialogContentText>
                </DialogContent>
          </Dialog>
        </div>
      </div>
      <ToastContainer />
      </ LoadingOverlay>
    </>
  );
}
