import React from "react";
import Header from "../components/header";
import "../css/AddChannel.css";
import { FaCartPlus, FaTrash } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import API from "../services/API";
import { useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import LoadingOverlay from "react-loading-overlay";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Mutiple_AddChannel() {
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  const { t } = useTranslation();
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setcustomer] = useState(location.state);
  const [alert, setalert] = useState(false);
  const [allChannel, setAllChannel] = useState([]);
  const [customerId,setcustomerId]=useState("")
  const [stb_id, setstb_id] = useState("");
  const [filterPacks, setFilterPacks] = useState([]);
  const [activePack, setActivePack] = useState([]);
  const [selectedPacks, setSelectedPacks] = useState([]);
  const [originalPack, setOriginalPack] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [selected, setselected] = useState("all");
  const [message, setMessage] = useState("");
  const [buttonText, setButtonText] = useState("no packs selected");
    const [status, setStatus] = useState("");
  const gotNavigatestate = location.state;

  console.log(gotNavigatestate);
  
  const [errormsg, seterrorMessage] = useState(null);

  const [collect, setCollect] = useState(false);
  const addToCart = (pack) => {
    setSelectedPacks([...selectedPacks, pack]);
    if (selected !== "all") {
      setAllChannel(
        allChannel.filter(
          (c) => c.packId !== pack.packId && c.packType === selected
        )
      );
      setFilterPacks(
        filterPacks.filter(
          (c) => c.packId !== pack.packId && c.packType === selected
        )
      );
    } else {
      setAllChannel(allChannel.filter((c) => c.packId !== pack.packId));
      setFilterPacks(filterPacks.filter((c) => c.packId !== pack.packId));
    }
  };
  const removeFromCart = (pack) => {
    setAllChannel([...allChannel, pack]);
    setFilterPacks([...filterPacks, pack]);
    setSelectedPacks(selectedPacks.filter((c) => c.packId !== pack.packId));
  };
  const dismissCollectAlert = () => {
    setCollect(false);
    window.location.reload();
  };
  const dismissUpdateAlert = () => {
    setCollect(false);
  };
   useEffect(() => {
     if (gotNavigatestate) {
       console.log(gotNavigatestate);
       setstb_id(gotNavigatestate.STB_ID);
       setcustomerId(gotNavigatestate.customerId);
       //  console.log("State Value's : ", gotNavigatestate);
       if (gotNavigatestate.SERVICE_STATUS === "Active") {
         setStatus("Active");
       } else if (gotNavigatestate.SERVICE_STATUS === "Suspended") {
         setStatus("Suspended");
       } else {
         setStatus("Cancelled");
       }
     }
   }, [gotNavigatestate]);
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleOptionsChange = (e) => {
    setselected(e.target.value);
  };
  const getAllChannels = () => {
    setIsLoading(true);
    // console.log(originalPack);
    const body = {
      operatorId: user.operatorId,
    };
    API.ADDallPacks(body)
      .then((response) => {
        console.log(response.data);
        console.log(originalPack);
        setAllChannel(
          response.data.all_packs.filter(
            (pack) =>
              !originalPack.some((opack) => opack.packId === pack.packId)
          )
        );
        setFilterPacks(
          response.data.all_packs.filter(
            (pack) =>
              !originalPack.some((opack) => opack.packId === pack.packId)
          )
        );
        setProgress(100);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProgress(false);
      });
  };
  const getActivePacks = () => {
    // const body = {
    //   customerId: "1000764129",
    //   operatorId: "1603",
    //   stbId: "39946673",
    // };
    const body = {
      customerId: customerId,
      operatorId: user.operatorId,
      stbId: stb_id,
    };
    API.activePacks(body)
      .then((response) => {
        // console.log(response.data.active_pac);
        setActivePack(response.data.active_pac);
        setOriginalPack(response.data.active_pac);
        setProgress(50);
      })
      .catch((err) => {
        console.log(err);
        setProgress(100);
      });
  };

  // useEffect(() => {
  //   console.log(originalPack);
  //   // console.log("check",allChannel.filter((pack) => !originalPack.some((opack)=>opack.packId===pack.packId)))
  //   setAllChannel(allChannel.filter((pack) => !originalPack.some((opack)=>opack.packId===pack.packId)));
  //   setFilterPacks(filterPacks.filter((pack) => !originalPack.some((opack)=>opack.packId===pack.packId)));
  // }, [originalPack]);

  useEffect(() => {
    if (selected !== "all") {
      setFilterPacks(
        allChannel.filter(
          (pack) =>
            pack.packName.toLowerCase().includes(search.toLowerCase()) &&
            pack.packType === selected
        )
      );
    } else {
      setFilterPacks(
        allChannel.filter((pack) =>
          pack.packName.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search]);
  useEffect(() => {
    if (selected !== "all") {
      setFilterPacks(allChannel.filter((pack) => pack.packType === selected));
    } else {
      // setFilterPacks(allChannel);
      // console.log("Called")
      getAllChannels();
    }
  }, [selected]);
  useEffect(() => {
    setProgress(30);
    getActivePacks();
    // getAllChannels();
  }, []);
  useEffect(() => {
    getAllChannels();
  }, [originalPack]);

  useEffect(() => {
    let total = 0;
    selectedPacks.forEach((pack) => {
      total += parseFloat(pack.packTotalPrice);
    });
    setTotal(total.toFixed(2));
    if (total > 0) {
      setButtonText("Add Channels ₹" + total.toFixed(2).toString());
    } else {
      setButtonText("no packs selected");
    }
  }, [selectedPacks, total]);

  const handleAddChannel = () => {
    if (user.editPermission === "true") {
      console.log(status);
      if (status === "Active") {
        setProgress(30);
        const body = {
          stb_id: stb_id,
          cust_num: customerId,
          pack_details: selectedPacks,
        };
        console.log("called");
        API.addChanel(body)
          .then((response) => {
            if (response.data.p_out_mssg_flg === "E") {
              setMessage(response.data.p_out_mssg);
              setalert(true);
              setSelectedPacks([]);
            } else {
              setMessage(response.data.p_out_mssg);
              setalert(true);
              setSelectedPacks([]);
            }
            setProgress(100);
          })
          .catch((err) => {
            console.log(err);
            setProgress(100);
          });
      } else {
        seterrorMessage("Only Active SetupBox Can Be Modified");
        setCollect(true);
      }
    } else {
      seterrorMessage("You Don't Have Permission to Modify the Pack");
      setCollect(true);
    }
  };

  const AddedChanneList = selectedPacks.map((pack) => {
    return (
      <div className="SingleChannelData" id={pack.packId}>
        <div class="legend">{pack.packType}</div>
        <div className="channel" id="channelName">
          {pack.packName}
        </div>
        <div className="spacer"></div>
        <div className="channel">₹ {pack.packTotalPrice}</div>
        <div className="channel">
          <FaTrash
            color="#DD2224"
            style={{ width: "20px", height: "20px" }}
            onClick={() => removeFromCart(pack)}
          />
        </div>
      </div>
      // </BorderWrapper>
      // </div>
    );
  });
  const ChannelList = filterPacks.map((pack) => {
    return (
      <div className="SingleChannelData" id={pack.packId}>
        <div class="legend">{pack.packType}</div>
        <div className="channel" id="channelName">
          {pack.packName}
        </div>
        <div className="spacer"></div>
        <div className="channel">₹ {pack.packTotalPrice}</div>
        <div className="channel">
          <FaCartPlus
            color="#0090DA"
            style={{ width: "20px", height: "20px" }}
            onClick={() => addToCart(pack)}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="AddChannelContain">
      <Header name={t("AD_lbl_Header")} link={"/addChannel"} />
      <LoadingBar
        color="#f11946"
        height={4}
        shadow={true}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <LoadingOverlay active={isLoading} spinner text="Please Wait...">
        <div className="addChannelMainContainer">
          <div className="SearchAndDropDownAaddChannel">
            <div className="SearchBarAddChannel">
              <input
                type="text"
                placeholder={t("AD_placeholder_Search_Channel")}
                id="SearchChannel"
                value={search}
                onChange={handleSearch}
              />
              {/* <AiFillCloseCircle
              style={{ width: "3vh", height: "2.5vh" }}
              onClick={() => setSearch("")}
            /> */}
            </div>
            <div className="DropDownAddChannel">
              <select
                name="area"
                id="area"
                className="DropChannel"
                value={selected}
                onChange={handleOptionsChange}
              >
                <option value={"Base Pack FTA"}>
                  {t("AD_drop_pack_Base")}
                </option>
                <option value={"MSO Bouquet"}>{t("AD_drop_pack_MSO")}</option>
                <option value={"Pay Channel"}>{t("AD_drop_pack_Pay")}</option>
                <option value={"Broadcaster Bouquet"}>
                  {t("AD_drop_pack_broadcaster")}
                </option>
                <option value="all">{t("AD_drop_pack_all")}</option>
              </select>
            </div>
          </div>

          {total > 0 && (
            <div
              className="ClearAlldiv"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "5vh",
              }}
              onClick={() => {
                setAllChannel([...allChannel, ...selectedPacks]);
                setSelectedPacks([]);
              }}
            >
              <p style={{ marginRight: "10px" }}>{t("AD_clear_ALL")}</p>
              <FaTrash
                color="#DD2224"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          )}
          <div className="AddChannelDataDiv">
            <div className="AddChannelData">
              {!search && AddedChanneList}
              {ChannelList}
            </div>
          </div>
          <div className="floating-AddChannel-div">
            <button
              className="AddChannelButton"
              disabled={total > 0 ? false : true}
              style={
                total > 0
                  ? { backgroundColor: "#0090DA" }
                  : { backgroundColor: "#D9D9D6" }
              }
              onClick={handleAddChannel}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </LoadingOverlay>
      <Dialog
        style={{ borderRadius: "25px" }}
        open={alert}
        onClose={() => setalert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontFamily: "Noto Sans", textAlign: "center" }}
        >
          Channel Added
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Noto Sans",
              color: "black",
              border: "none",
              margin: "10px",
              padding: "5px",
            }}
          >
            {message}
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Noto Sans",
              background: "var(--primay-app-color)",
              color: "white",
              border: "none",
              borderRadius: "20px",
              margin: "15px",
              padding: "10px",
            }}
            onClick={dismissUpdateAlert}
          >
            OK
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={collect}
        onClose={() => setCollect(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontFamily: "Noto Sans" }}
        >
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Noto Sans",
              color: "black",
              border: "none",
              margin: "10px",
              padding: "5px",
            }}
          >
            <p>{errormsg}</p>
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Noto Sans",
              background: "var(--primay-app-color)",
              color: "white",
              border: "none",
              borderRadius: "20px",
              margin: "15px",
              padding: "10px",
            }}
            onClick={dismissCollectAlert}
          >
            OK
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
