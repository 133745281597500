import React from "react";
import Header from "../components/header";
import "../css/AddChannel.css";
import "../css/SelectedPack.css";
import { FaCartPlus, FaTrash } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../css/header.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../services/API";
import { useTranslation } from "react-i18next";
export default function SelectedPacks() {
  const user = JSON.parse(
    localStorage.getItem("user") || sessionStorage.getItem("user")
  );
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [activepack, setActivePack] = useState(location.state.activePack);
  const [allpacks, setAllPacks] = useState(location.state.originalPack);
  const [customer, setCustomer] = useState(location.state.customer);
  console.log(allpacks);
  const [total, setTotal] = useState(0);
  const [noofpacks, setnoofpacks] = useState(0);
  useEffect(() => {
    // calculate totalprice andno of active packs
    let totalprice = 0;
    let noofpacks = 0;
    activepack.map((pack) => {
      totalprice = totalprice + parseFloat(pack.packTotalPrice);
      noofpacks = noofpacks + 1;
    });
    setTotal(totalprice);
    setnoofpacks(noofpacks);
  }, []);
  const AddedChanneList = activepack.map((pack) => {
    return (
      <div className="SingleChannelData" id={pack.packId}>
        <div class="legend">{pack.packType}</div>
        <div className="channel" id="channelName">
          {pack.packName}
        </div>
        <div className="spacer"></div>
        <div className="channel" style={{ width: "100px" }}>
          ₹ {pack.packTotalPrice}
        </div>
        <div className="channel">
          <FaTrash
            color="#DD2224"
            style={{ width: "25px", height: "25px" }}
            // onClick={() => removeFromCart(pack)}
          />
        </div>
      </div>
      // </BorderWrapper>
      // </div>
    );
  });

  // Date handling
  const [fromDate, setFromDate] = useState("");
  const [noOfMonths, setNoOfMonths] = useState(1);
  const [toDate, setToDate] = useState("");

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    const fromDateObj = new Date(event.target.value);
    const toDateObj = new Date(
      fromDateObj.getTime() + noOfMonths * 30 * 24 * 60 * 60 * 1000
    );
    setToDate(toDateObj.toISOString().slice(0, 10)); // convert to ISO string and extract YYYY-MM-DD format
  };

  const handleNoOfMonthsChange = (event) => {
    setNoOfMonths(parseInt(event.target.value));
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(
      fromDateObj.getTime() +
        parseInt(event.target.value) * 30 * 24 * 60 * 60 * 1000
    );
    setToDate(toDateObj.toISOString().slice(0, 10)); // convert to ISO string and extract YYYY-MM-DD format
  };

  const formattedDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("en-GB"); // "en-GB" for DD/MM/YY format
  };
  const getUniqueTranscationId = () => {
    // format 8RNEW0304075019761241512417
    const date = new Date();
    const year = date.getFullYear().toString().slice(2, 4);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");
    const random = Math.floor(Math.random() * 10000).toString().padStart(4, "0");
    return `8RNEW${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${random}`;
    
  }
  const handleRenewSubmit = (e) => {
    e.preventDefault();
    if(fromDate === "") {
      toast.error(t("Selectedpacks_lbl_error_toast"));
      return;
    }
    if(noOfMonths === 0) {
      toast.error("Please select a valid number of months");
      return;
    }
    // console.log("todate", formattedDate(fromDate));
    const data ={
      stb_id: customer.stb_id,
      flag :"S",
      other_details:{
        customer_id : customer.customerId,
        agent_id : user.agentId,
        duration : noOfMonths,
        start_date : formattedDate(fromDate),
        flag : "S",
        operator_id : user.operatorId,
        end_date : toDate,

      },
      pack_details : activepack,
      active_pack_details : allpacks,
      transactionID: getUniqueTranscationId()
    }

    API.modifyPacks( data)
      .then((res) => {
        console.log(res);
        if(res.data.p_out_mssg_flg === "S") {
          toast.success(t("Selectedpacks_lbl_success_toast"));
          // take 2 secs delay and navigate to customer dashboard
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          toast.error("Failed to renew packs");
        }
      }
      )
      .catch((err) => {
        console.log(err);
        toast.error("Failed to renew packs");
      }
      );
    
  }
  return (
    <div className="selectedPackMainPack">
      <div className="upper-header">
        <div style={{}}>
          <h2 className="upper-header-label">{t("Selectedpacks_lbl_Header")}</h2>
        </div>
      </div>
      <div className="selectedPackMain">
        <div className="selectedPackMainData">{AddedChanneList}</div>
      </div>
      <div className="selectedPackMainFooter">
        <div className="SelecetedPacLine"></div>
        <div className="selectedPackMainFooterData">
          <table className="packselectedtable">
            <tr>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "16px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#333333",
                  fontWeight: "700",
                }}
              >
                {t("Selectedpacks_lbl_packs_selected")} :{" "}
              </td>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "16px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#34A55A",
                  fontWeight: "700",
                }}
              >
                {noofpacks}
              </td>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "16px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#333333",
                  fontWeight: "700",
                }}
              >
                {t("Selectedpacks_lbl_packs_amount")} :{" "}
              </td>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "16px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#34A55A",
                  fontWeight: "700",
                }}
              >
                ₹ {total}
              </td>
            </tr>
          </table>
          <table
            className="selectedPackDateTable"
            style={{
              marginTop: "30px",
            }}
          >
            <tr>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#333333",
                  fontWeight: "400",
                }}
              >
                {t("Selectedpacks_lbl_from_date")} <FaCalendar color="#007ABC" />
              </td>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#333333",
                  fontWeight: "400",
                }}
              >
                {t("Selectedpacks_lbl_numberof_months")}
              </td>
              <td
                style={{
                  width: "fit-content",
                  fontSize: "12px",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  color: "#333333",
                  fontWeight: "400",
                }}
              >
                {t("Selectedpacks_lbl_end_date")} <FaCalendar color="#007ABC" />
              </td>
            </tr>
            <tr
              style={{
                marginTop: "-10px",
              }}
            >
              <td>
                <input
                  type="date"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  style={{
                    width: "fit-content",
                    border: "none",
                    borderBottom: "2px solid #007ABC",
                  }}
                />
              </td>
              <td>
                <select
                  name="noofmonths"
                  id="noofmonths"
                  value={noOfMonths}
                  onChange={handleNoOfMonthsChange}
                  style={{
                    width: "fit-content",
                    border: "none",
                    borderBottom: "2px solid #007ABC",
                  }}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </td>
              <td>
                <input
                  type="date"
                  value={toDate}
                  readOnly
                  style={{
                    width: "fit-content",
                    border: "none",
                    borderBottom: "2px solid #007ABC",
                  }}
                />
              </td>
            </tr>
          </table>
          <div className="selectedButtonPackdiv">
            <button
              style={{
                background: "#0090DA",
                borderRadius: "20px",
                width: "150px",
                height: "48px",
                fontSize: "16px",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                color: "#FFFFFF",
                fontWeight: "700",
                border: "none",
                // text in caps
                textTransform: "uppercase",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("Selectedpacks_lbl_back")}
            </button>
            <button
              style={{
                background: "#0090DA",
                borderRadius: "20px",
                border: "none",
                width: "150px",
                height: "48px",
                fontSize: "16px",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                color: "#FFFFFF",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              onClick={handleRenewSubmit}
            >
              {t("Selectedpacks_lbl_submit")}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
